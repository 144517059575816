import {CustomFormField} from "../../types/interfaces";
import {FormControl, FormLabel, RadioGroup, FormControlLabel, Radio} from "@mui/material";
import React from "react";

export function EditRadioGroup<T>(props: { item: T, setItem: any, field: CustomFormField }) {
    return (<>
        <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">{props.field.label}</FormLabel>
            <RadioGroup
                aria-label={props.field.key}
                name={props.field.key}
                value={JSON.stringify(props.item[props.field.key as keyof T])}
                onChange={(e) => {
                    let newItem = {...props.item};
                    newItem[props.field.key as keyof T] = JSON.parse(e.target.value as unknown as T[keyof T] as string);
                    props.setItem(newItem);
                }}

            >
                {Object.values(props.field.options || []).map((option, index) => {
                    let opt = option as { value: any, label: string };
                    return (
                        <FormControlLabel
                            key={opt.value || index}
                            value={JSON.stringify(opt.value)}
                            control={<Radio />}
                            label={opt.label}
                            disabled={props.field.readonly}
                        />
                    )
                })}
            </RadioGroup>
        </FormControl>
    </>)
}
