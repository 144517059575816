// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import {
    DataGrid,
    GridColDef, GridRowModel,
    GridValidRowModel
} from '@mui/x-data-grid';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {CustomFormField, IApplicationItem, IUser} from "../../types/interfaces";
// import {ChangeUserController, ResendNewUserEmailController, UserDataController} from "./UserDataController";
import {AuthContext} from "../../Library/AuthContext";
import {CustomFormFieldSizes, CustomFormFieldTypes, DEFAULT_PAGE_SIZE_OPTIONS, UserRole} from "../../types/enums";
import {
    Box,
    Button,
    FormControlLabel, Modal,
    Stack,
    Switch,
    Typography
} from "@mui/material";

import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../Hooks/useLocalStorage";
import {IPaginated, PaginatedInput} from "../Users/UserDataController";
import {EditObject} from "../../Shared/Components/EditObject";
import App from "../../App";



export const AddNewApplicationItem = ({ open, onClose, onAddNewApplicationItem } : { open: boolean, onClose: () => void, onAddNewApplicationItem: (item: IApplicationItem) => Promise<boolean> }) => {
    const [item, setItem] = React.useState<any>({});

    const {
        user,
        userToken,
    } = React.useContext(AuthContext)!;

    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "name",
                    label: "Name",
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "apple_id",
                    label: "Apple ID",
                },

            ];


            return fields;
        },
        [item]
    );

    const disableButton = React.useMemo(() => {
        return item.name === undefined || item.name === "" ||
            item.apple_id === undefined || item.apple_id === ""
            // item.phone_number === undefined || item.phone_number === "" ||
            // item.role === undefined || item.role === "";
    }, [item]);

    return (<>
        <Modal open={open} onClose={() => {
            setItem({});
            onClose();
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '60%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    Create New Application
                </Typography>
                <EditObject
                    item={item}
                    setItem={setItem}
                    form={fields}
                    columns={12}
                />
                <br />

                <Button type={"submit"} variant={"contained"} size={"large"} disabled={disableButton} fullWidth={true} onClick={async () => {
                    console.log("100")
                    if (user === undefined || user === null || user.team_id === undefined || user.team_id === null) {
                        // console.log(user?.team_id)
                        return;
                    }

                    let applicationToCreate: IApplicationItem = {
                        _id: "",
                        name: item.name,
                        apple_id: item.apple_id,
                        team_id: item.team_id
                    }
                    console.log("112")

                    try {
                        if (await onAddNewApplicationItem(applicationToCreate)) {
                            setItem({});
                            onClose();
                            console.log("118")
                        } else {
                            // alert("Invalid User");
                            alert("Could not create application item");
                        }
                    }
                    catch (e) {
                        const error = e as Error;
                        console.log(error);
                        // alert("Error Creating User");
                        switch (error.message) {
                            case "Conflict":
                                alert("Application with name already exists..");
                                return;
                        }
                        alert(`Could not create user, error occurred. ${error.message}`);
                    }


                }}>CREATE</Button>
            </Box>
        </Modal>
    </>);
};






export class ApplicationItemController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }



    public async create(item: IApplicationItem): Promise<IApplicationItem> {
        console.log(item);
        let results = await fetch(`${this.baseURL}/api/applications`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check.
    }

    // public async get(): Promise<IApplicationItem[]> {
    //     let results = await fetch(`${this.baseURL}/api/applications`, {
    //         method: "GET",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         // body: JSON.stringify(item)
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check.
    // }

    public async get(filter: PaginatedInput, searchText: String): Promise<IPaginated<IApplicationItem>> {
        let results = await fetch(`${this.baseURL}/api/applications/?page=${filter.page}&limit=${filter.pageSize}&filter=${searchText}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

}


function ApplicationManagementView() {

    const {
        user,
        userToken,
        setUserToken,
        setUserAccessToken,
        setAdminUserToken
    } = React.useContext(AuthContext)!;

    const navigate = useNavigate();

    // const processRowUpdate = React.useCallback(
    //     async (newRow: GridRowModel, oldRow: GridRowModel) => {
    //         if (userToken === null || userToken === undefined) {
    //             return;
    //         }
    //
    //         return await new ApplicationItemController(userToken).update(newRow._id, newRow as IUser);
    //     },
    //     [userToken],
    // );

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    });
    const [totalRows, setTotalRows] = React.useState<number>(0);
    const [searchText, setSearchText] = useLocalStorage("ApplicationManagementView.searchText", "") as [string, React.Dispatch<React.SetStateAction<string>>];


    const UpdateList = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let response = await new ApplicationItemController(userToken).get(paginationModel, searchText);
        console.log(response)
        if (response !== null && response !== undefined) {
            if (response.items !== undefined && response.count !== undefined){
                setList(response.items);
                setTotalRows(response.count)
            }
        }
    }, [userToken, paginationModel, searchText]);

    const columns = React.useMemo<GridColDef[]>(() => {

        const columns: GridColDef[] = [
            { field :'name', headerName: 'Name', width: 250, editable: false },
            { field :'apple_id', headerName: 'Apple ID', width: 250, editable: false },
            // { field :'phone_number', headerName: 'Phone Number', width: 150, editable: true },
        ]

        //
        //
        //
        // columns.push({ field: 'reset password', headerName: ' ', width: 180, editable: false,
        //     renderCell: (params) => (
        //         <>
        //             <Button variant="outlined" color="primary"
        //                 // disabled={true}
        //                     disabled={ (params.row as IUser).email_address === "justin.allen@pivital.com" || (params.row as IUser).isDeactivated }
        //                     fullWidth={true} onClick={async () => {
        //                 if (window.confirm(`Are you sure you want to reset the password for [${(params.row as IUser).email_address}?`)) {
        //                     if (userToken === null || userToken === undefined) {
        //                         return;
        //                     }
        //                     let controller = new UserDataController(userToken);
        //                     let updatedUser = {
        //                         ...(params.row as IUser),
        //                         password: `password_${(params.row as IUser).email_address}`
        //                     };
        //                     let result = await controller.update((params.row as IUser)._id, updatedUser);
        //                     if (result === undefined) {
        //                         return;
        //                     }
        //                     await UpdateList();
        //                 }
        //             }}>Reset Password</Button>
        //         </>
        //     ),
        // });


        columns.push({ field: 'createdAt', headerName: 'Created At', width: 250, editable: false,
            valueFormatter: (params) => {
                try {
                    if (params.value === undefined || params.value === null) {
                        return "";
                    }
                    let date = new Date(params.value as string);
                    return date.toLocaleString();
                }
                catch (e) {
                    // console.log(e);
                    return "";
                }

            }
        })

        columns.push({ field: 'updatedAt', headerName: 'Updated At', width: 250, editable: false,
            valueFormatter: (params) => {
                try {
                    if (params.value === undefined || params.value === null) {
                        return "";
                    }
                    let date = new Date(params.value as string);
                    return date.toLocaleString();
                }
                catch (e) {
                    // console.log(e);
                    return "";
                }

            }
        })

        return columns;
    }, [user, userToken, setUserToken, setAdminUserToken, /*processRowUpdate,*/ UpdateList, navigate, setUserAccessToken]);



    React.useEffect(() => {
        UpdateList().then();
    }, [userToken, paginationModel.page, paginationModel.pageSize, searchText, UpdateList]);

    const [list, setList] = React.useState<IApplicationItem[]>([]);
    const [showAddNewApplicationModal, setShowAddNewApplicationModal] = React.useState<boolean>(false);

    // const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    //     console.log('handleProcessRowUpdateError', error)
    //     alert(error.message);
    //     // setSnackbar({ children: error.message, severity: 'error' });
    // }, []);

    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Applications"}
                 onAddClicked={() => {
                     setShowAddNewApplicationModal(true)
                 }}
                  searchText={{text: searchText, setText: setSearchText, label: "Search (Name)"}}
            >
                <DataGrid
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: '100%' }}
                    rows={list}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    disableRowSelectionOnClick
                    // processRowUpdate={processRowUpdate}
                    // onProcessRowUpdateError={handleProcessRowUpdateError}


                    pagination={true}
                    rowCount={totalRows}
                    paginationMode={"server"}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                />

                <AddNewApplicationItem open={showAddNewApplicationModal} onClose={() => {
                    setShowAddNewApplicationModal(false)
                }} onAddNewApplicationItem={async (item) => {
                    console.log("353")
                    if (userToken === null || userToken === undefined) {
                        console.error("354")
                        return false;
                    }

                    await new ApplicationItemController(userToken).create(item);
                    await UpdateList();

                    return true;
                }} />

                {/*<AddNewUser open={showAddNewUserModal} onClose={() => {*/}
                {/*    setShowAddNewUserModal(false)*/}
                {/*}} onAddNewUser={async (user) => {*/}
                {/*    if (userToken === null || userToken === undefined) {*/}
                {/*        return false;*/}
                {/*    }*/}

                {/*    await new UserDataController(userToken).create(user);*/}
                {/*    await UpdateList()*/}
                {/*    // let all = await new UserDataController(userToken).getAll();*/}
                {/*    // setList(all)*/}
                {/*    return true;*/}
                {/*}} />*/}

            </AuthenticatedLayout>
        </div>
    );
}

export default ApplicationManagementView;
