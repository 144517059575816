import {CustomFormField} from "../../types/interfaces";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import React from "react";

export function EditSelect<T>(props: { item: T, setItem: any, field: CustomFormField }) {
    return (<>
        {/*<pre>{ JSON.stringify( props.item[props.field.key as keyof T] ) }</pre>*/}
        <FormControl fullWidth>
            <InputLabel id={"_label_" + props.field.key}>{props.field.label}</InputLabel>



            <Select
                labelId={"_label_" + props.field.key}
                id={"_input_" + props.field.key}
                value={ JSON.stringify(props.item[props.field.key as keyof T] ) }
                label={props.field.label}
                onChange={(e)=>{
                    let newItem = {...props.item};
                    newItem[props.field.key as keyof T] = JSON.parse( e.target.value as unknown as T[keyof T] as string );
                    props.setItem(newItem);
                }}
                disabled={props.field.readonly}
            >
                {Object.values(props.field.options || []).map((option, index) => {
                    //  as {value: any, label: string}
                    let opt = option as {value: any, label: string, selected?: boolean};
                    return <MenuItem key={opt.value || index} value={JSON.stringify(opt.value)}>{opt.label}</MenuItem>
                })}
            </Select>
        </FormControl>
    </>)
}
