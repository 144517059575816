
import {Navigate} from "react-router-dom";
import React from "react";
import UnauthorizedView from "../AuthenticatedViews/UnauthorizedView";

export type ProtectedRouteProps = {
    getAuthenticationStatus: () => AuthenticationStatus;
    authenticationPath: string;
    outlet: JSX.Element;
};

export enum AuthenticationStatus {
    UNAUTHENTICATED,
    UNAUTHORIZED,
    AUTHORIZED
}


// Authorized and Authenticated, UnAuthorized and Authenticated, UnAuthorized and UnAuthenticated

export default function ProtectedRoute({
   authenticationPath,
   outlet,
   getAuthenticationStatus,
}: ProtectedRouteProps) {
    switch (getAuthenticationStatus()) {
        case AuthenticationStatus.UNAUTHENTICATED:
            return <Navigate to={{ pathname: authenticationPath }} />;
        case AuthenticationStatus.UNAUTHORIZED:
            return (<>
                <UnauthorizedView />
            </>)
        case AuthenticationStatus.AUTHORIZED:
            return outlet;
    }
};
