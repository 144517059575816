import React from 'react';
import './App.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import UnauthenticatedMainView from "./Views/UnauthenticatedMainView";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ProtectedRoute, {AuthenticationStatus, ProtectedRouteProps} from "./Views/PrivateRoute";
import {useLocalStorage} from "./Hooks/useLocalStorage";
import {orange} from "@mui/material/colors";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";
import UserManagementView from "./Modules/Users/UserManagementView";
import {IUser} from "./types/interfaces";
import {UserRole} from "./types/enums";
import {AuthContext, AuthContextType} from "./Library/AuthContext";
import {SidebarContext, SidebarContextType} from "./Library/SidebarContext";
import ProfileView from "./Modules/Profile/ProfileView";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import ApplicationManagementView from "./Modules/ApplicationManagement/ApplicationManagementView";
import NotificationManagerView from "./Modules/NotificationManager/NotificationManagerView";
import SessionManagementView from "./Modules/Sessions/SessionManagementView";
import ErrorBoundary from "./Library/ErrorBoundary";
// import SentEmailsView from "./Modules/SentEmails/SentEmailsView";
import { LicenseInfo} from "@mui/x-data-grid-pro";
import UsersDashboardView from "./Modules/Users/UsersDashboard";
import ApplicationEventView from "./Modules/ApplicationManagement/ApplicationEventView";

let authenticationLoading = false;


LicenseInfo.setLicenseKey("98b4a6c97bc2d63a46f149527c8dd597Tz04OTM4NSxFPTE3NDYwMjM1NDQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=")

function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const theme = React.useMemo(
      () =>
          createTheme({
            palette: {
              mode: prefersDarkMode ? 'dark' : 'light',
                primary: {
                    main: orange[900],
                },
                secondary: {
                    main: "#22222", //blue[900],
                    dark: "#FFFFFF"
                },
            },
          }),
      [prefersDarkMode],
    );

    const [userAccessToken, setUserAccessToken] = useLocalStorage("userAccessToken", null) as [string | null, React.Dispatch<React.SetStateAction<string | null>>];
    const [userRefreshToken, setUserRefreshToken] = useLocalStorage("userRefreshToken", null) as [string | null, React.Dispatch<React.SetStateAction<string | null>>];
    const [userTokenExpiration, setUserTokenExpiration] = useLocalStorage("userTokenExpiration", null) as [Date | null, React.Dispatch<React.SetStateAction<Date | null>>];



    const [userToken, setUserToken] = useLocalStorage("userToken", null) as [string | null, React.Dispatch<React.SetStateAction<string | null>>];

    const [user, setUser] = React.useState(null) as [IUser | null, React.Dispatch<React.SetStateAction<IUser | null>>];
    const [loadedUserToken, setLoadedUserToken] = React.useState<string | null>(null);

    const [adminUserToken, setAdminUserToken] = useLocalStorage("adminUserToken", null) as [string | null, React.Dispatch<React.SetStateAction<string | null>>];

    // const [tokenExpired, setTokenExpired] = React.useState<boolean>(false);

    const fetchUser = React.useCallback(async () => {
        if (authenticationLoading){
            return;
        }

        if (loadedUserToken === userToken) {
            return;
        }
        setLoadedUserToken(userToken);
        authenticationLoading = true;
        try {
            if (userToken === null || userToken === undefined) {
                setUser(null);
                return;
            }
            const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
            let results = await fetch(`http${ process.env.REACT_APP_SERVER_SECURE ? "s" :"" }://${baseURL}/api/auth/me`, {
                method: "POST",
                headers: {
                    "authorization": `Bearer ${userToken}`,
                    "Content-Type": "application/json"
                }
            });

            if (results.status !== 200) {
                console.log("clearing token")
                setUserToken(null);
            }
            else {
                let data = await results.json();
                setUser(data);
            }
        } catch (e) {
            console.log(e);
            if (user !== null) {
                setUser(null);
            }
            alert("Your session has expired. Please log in again.")
        }
        finally {
            authenticationLoading = false;
        }
    }, [userToken, user, setUser, setUserToken, loadedUserToken]);

    React.useEffect(() => {
        fetchUser().then(r => {});
    }, [userToken, setUser, fetchUser]);


    const [showSidebar, setShowSidebar] = useLocalStorage("showSidebar", true);

    const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
        getAuthenticationStatus: () => {
            if (userToken === null || user === null) {
                return AuthenticationStatus.UNAUTHENTICATED;
            }
            return AuthenticationStatus.AUTHORIZED;
        },
        authenticationPath: '/'
    };

    const userIncludesRole = (roles: UserRole[]) => {
        console.log(`Attempting to determine role. ${userToken} ${user} '${user?.role}' =? '${roles}'`)
        if (userToken === null || user === null) {
            return AuthenticationStatus.UNAUTHENTICATED;
        }

        if (user.role === null || user.role === undefined) {
            return AuthenticationStatus.UNAUTHORIZED;
        }
        for (let role of roles) {
            if (user.role.includes(role)) {
                return AuthenticationStatus.AUTHORIZED;
            }
        }
        return AuthenticationStatus.UNAUTHORIZED;
    }

    React.useEffect(() => {

        async function refreshToken() {
            try {
                const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
                // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;

                // TODO: finish this, it's not sending the object yet.
                let results = await fetch(`http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}/api/auth/refresh`, {
                    method: "POST",
                    headers: {
                        // "authorization": `Bearer ${this.userToken}`,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        refreshToken: userRefreshToken
                    })
                });
                let data = await results.json();
                console.log(data);
                let { accessToken, refreshToken, expirationDate } = data;
                if (accessToken === undefined || refreshToken === undefined || expirationDate === undefined) {
                    return
                }


                // setTokenExpired(false);
                setUserToken(accessToken);
                setUserAccessToken(accessToken);
                setUserRefreshToken(refreshToken);
                setUserTokenExpiration(expirationDate);
                console.log("refreshed token")

                // navigate('/'); // TODO: figure out how to make this work with the useCallback
            }
            catch (e) {
                console.log(e);
                // setErrorMessage("Invalid Credentials");
            }
        }

        if (userTokenExpiration === null || userTokenExpiration === undefined) {
            return;
        }

        const currentTime = new Date();
        const expirationTime = new Date(userTokenExpiration);

        // alert("checking token expiration: " + userTokenExpiration)

        // Calculate the time difference in milliseconds between the current time and target time
        const timeDifference = expirationTime.getTime() - currentTime.getTime();

        if (timeDifference > 0) {
            // If the time difference is positive, schedule a timeout to trigger the function
            const timerId = setTimeout(() => {
                // This function will be executed when the timeout is reached
                console.log('Function triggered at target time.');

                refreshToken().then(r => {})
                // TODO: bring this back!!!!!

                // You can call your specific function here
                // For example: myFunctionToTrigger();
            }, timeDifference);

            return () => {
                // Clean up the timer if the component unmounts or if the target time changes
                clearTimeout(timerId);
            };
        }


    }, [userTokenExpiration, setUserAccessToken, setUserRefreshToken, setUserToken, userRefreshToken, setUserTokenExpiration]);

    const authContextValue: AuthContextType = {
        userToken,
        setUserToken,
        userAccessToken,
        setUserAccessToken,
        userRefreshToken,
        setUserRefreshToken,
        userTokenExpiration,
        setUserTokenExpiration,

        user,
        setUser,
        adminUserToken,
        setAdminUserToken,
    } ;

    const sidebarContextValue: SidebarContextType = {
        showSidebar,
        setShowSidebar
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
            {/*<Elements stripe={stripePromise}>*/}
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <AuthContext.Provider value={authContextValue}>
                    <SidebarContext.Provider value={sidebarContextValue}>
                        <ErrorBoundary>
                            <div>
                                <BrowserRouter>

                                    {!authenticationLoading && (userToken === null || userToken === undefined) && (<>
                                        <Routes>
                                            <Route index element={<UnauthenticatedMainView /> }/>
                                            <Route path="*" element={<UnauthenticatedMainView />} />
                                        </Routes>
                                    </>)}


                                    {!authenticationLoading && (user !== null && userToken !== null) && (<>
                                        <Routes>
                                            <Route index element={<ApplicationManagementView/>}/>
                                            <Route path="applications/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ApplicationEventView />} />} />
                                            {/*<Route path="template" element={<TemplateManagementView/> }/>*/}

                                            <Route path="dashboard">
                                                <Route path="users" element={<ProtectedRoute {...defaultProtectedRouteProps} getAuthenticationStatus={() => (userIncludesRole([UserRole.ADMIN]))} outlet={<UserManagementView />} />} />
                                                <Route path="userDashboard" element={<ProtectedRoute {...defaultProtectedRouteProps} getAuthenticationStatus={() => (userIncludesRole([UserRole.ADMIN]))} outlet={<UsersDashboardView />} />} />
                                            {/*    <Route path="sentEmails" element={<ProtectedRoute {...defaultProtectedRouteProps} getAuthenticationStatus={() => (userIncludesRole([UserRole.ADMIN]))} outlet={<SentEmailsView />} />} />*/}
                                                <Route path="sessions" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SessionManagementView />} />} />
                                                <Route path="profile" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ProfileView />} />} />

                                            {/*    /!*<Route path="bank" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<BankManagementView />} />} />*!/*/}
                                            {/*    <Route path="bank/objects" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<QuickObjectView />} />} />*/}
                                            {/*    <Route path="bank/question" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<QuickQuestionView />} />} />*/}
                                            {/*    <Route path="bank/group" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<QuickQuestionGroupView />} />} />*/}
                                            {/*    /!*<Route path="webhooks" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<WebHookManagementView />} />} />*!/*/}
                                            {/*    <Route path="surveys/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SurveyEditorView />} />} />*/}
                                            {/*    <Route path="surveyMutex" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SurveyMutexManagementView />} />} />*/}
                                            {/*    <Route path="cases/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<CaseView />} />} />*/}
                                            {/*    /!*<Route path="cases/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SurveyEditorView />} />} />*!/*/}
                                            {/*    <Route path="cases" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<CaseManagementView />} />} />*/}
                                            {/*    <Route path="timeEntries" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<TimeEntriesView />} />} />*/}
                                            {/*    <Route path="importSurveys" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ExcelImportSurveys />} />} />*/}
                                            {/*    <Route path="importUsers" element={<ProtectedRoute {...defaultProtectedRouteProps} getAuthenticationStatus={() => (userIncludesRole([UserRole.ADMIN, UserRole.USER_MANAGER]))} outlet={<ExcelImportUsers />} />} />*/}
                                            {/*    /!*<Route path="communication" element={<ProtectedRoute {...defaultProtectedRouteProps} getAuthenticationStatus={() => (userIncludesRole([UserRole.ADMIN]))} outlet={<CommunicationTemplateManagementView />} />} />*!/*/}
                                            {/*    <Route path="companies" element={<ProtectedRoute {...defaultProtectedRouteProps} getAuthenticationStatus={() => (userIncludesRole([UserRole.ADMIN]))} outlet={<ProjectsManagementView />} />} />*/}
                                            {/*    <Route path="archived_surveys" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ArchivedSurveysListView />} />} />*/}
                                            {/*    {user.role === UserRole.CLIENT ? <Route path="surveyAnswer/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ReadonlySurveyAnswerView />} />} /> : <Route path="surveyAnswer/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SurveyAnswerView />} />} />}*/}
                                            {/*    <Route path="surveyAnswer/:id" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<SurveyAnswerView />} />} />*/}
                                            {/*    <Route path="reports" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ReportManagementView />} />} />*/}
                                            {/*    <Route path="notifications" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<NotificationManagerView />} />} />*/}
                                            </Route>

                                            <Route path="*" element={<p>There's nothing here: 404!</p>} />
                                        </Routes>
                                    </>)}
                                </BrowserRouter>
                            </div>
                        </ErrorBoundary>
                    </SidebarContext.Provider>
                </AuthContext.Provider>
              </ThemeProvider>
            {/*</Elements>*/}
            </LocalizationProvider>
        </DndProvider>
    );
}

export default App;
