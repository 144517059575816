import { CustomFormField } from "../../types/interfaces";
import {TextField} from "@mui/material";
import React from "react";

export function EditTextArea<T>(props: { item: T, setItem: any, field: CustomFormField }) {

    return (
        <>
            <TextField
                fullWidth={true}
                multiline={true}
                rows={props.field.multilineRows || 2}
                type={props.field.kind}
                // type={props.field.kind === CustomFormFieldTypes.PASSWORD ? "password" : props.field.kind}
                label={props.field.label}

                value={props.item[props.field.key as keyof T]}
                onChange={(e) => {
                    let newItem = {...props.item};
                    newItem[props.field.key as keyof T] = e.target.value as unknown as T[keyof T];
                    props.setItem(newItem);
                }}
                disabled={props.field.readonly}
            ></TextField>
        </>
    )
}
