import {Button, ListItemButton, ListItemIcon, Paper, Stack, Typography} from "@mui/material";
import List from "@mui/material/List";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserCircle, faUsers, faHome, faBackward, faForward} from "@fortawesome/free-solid-svg-icons";
import ListItemText from "@mui/material/ListItemText";
import LogoutIcon from "@mui/icons-material/Logout";
import ListItem from "@mui/material/ListItem";
import React from "react";
import ScrollPane from "./ScrollPane";
import {UserRole} from "../types/enums";
import {AuthContext} from "./AuthContext";
import {useNavigate} from "react-router-dom";
import {
    ChangeUserController,
    LogoutController,
    ResendNewUserEmailController,
    UserDataController
} from "../Modules/Users/UserDataController";
import {IApplicationItem, IUser} from "../types/interfaces";
import {useLocalStorage} from "../Hooks/useLocalStorage";
import {GridColDef} from "@mui/x-data-grid";
import {UserRoleChangeCell} from "../Modules/Users/UserRoleChangeCell";
import {UserSessionsModal} from "../Modules/Users/UserSessionsModal";
import {ApplicationItemController} from "../Modules/ApplicationManagement/ApplicationManagementView";
import {faPhone} from "@fortawesome/pro-thin-svg-icons";

/*

url
text
icon
allowedRoles
restrictedRoles

the code below fails to use the correct roles.

 */

enum PermissionMode {
    all,
    allowList,
    blocklist
}

interface SidebarItem {
    url: string;
    text: string;
    icon: any;
    mode: PermissionMode;
    list: UserRole[];
}

function SideBarItemView(props: { item: SidebarItem, user: IUser, saveRequired: boolean }) {
    const {
        url,
        text,
        icon,
        mode,
        list
    } = props.item;
    const {
        saveRequired
    } = props;

    let navigate = useNavigate();

    // {user !== undefined && user !== null && user.role !== undefined && (user.role.includes(UserRole.ADMIN) || user.role.includes(UserRole.MANAGER))

    switch (mode) {
        case PermissionMode.all:
            return (
                <ListItemButton
                    onClick={() => {
                        navigate(url);
                    }}
                    disabled={saveRequired}
                >
                    <ListItemIcon>
                        <FontAwesomeIcon icon={icon} />
                    </ListItemIcon>
                    <ListItemText
                        primary={text}
                    />
                </ListItemButton>
            );
            break;
        case PermissionMode.allowList:
            if (list.length > 0 && props.user !== undefined && props.user !== null && props.user.role !== undefined) {
                if (list.includes(props.user.role)) {
                    return (
                        <ListItemButton
                            onClick={() => {
                                navigate(url);
                            }}
                            disabled={saveRequired}
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon icon={icon} />
                            </ListItemIcon>
                            <ListItemText
                                primary={text}
                            />
                        </ListItemButton>
                    );
                }

            }
            break;
        case PermissionMode.blocklist:
            if (list.length > 0 && props.user !== undefined && props.user !== null && props.user.role !== undefined) {
                if (!list.includes(props.user.role)) {
                    return (
                        <ListItemButton
                            onClick={() => {
                                navigate(url);
                            }}
                            disabled={saveRequired}
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon icon={icon} />
                            </ListItemIcon>
                            <ListItemText
                                primary={text}
                            />
                        </ListItemButton>
                    );
                }

            }
            break;
    }

    return (<></>);
}

// interface SidebarDivider {}

function Sidebar(props: {saveRequired: boolean | undefined}) {
    const {
        user,
        userToken,
        userAccessToken,
        setUserToken,
        setUserAccessToken,
        setUserRefreshToken,
        setUserTokenExpiration,
        adminUserToken,
        setAdminUserToken
    } = React.useContext(AuthContext)!;

    const {
        saveRequired
    } = props;

    let navigate = useNavigate();

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    });

    const [totalRows, setTotalRows] = React.useState<number>(0);
    const [searchText, setSearchText] = useLocalStorage("Sidebar.searchText", "") as [string, React.Dispatch<React.SetStateAction<string>>];

    const UpdateList = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let response = await new ApplicationItemController(userToken).get(paginationModel, searchText);
        if (response !== null && response !== undefined) {
            if (response.items !== undefined && response.count !== undefined){
                setList(response.items);
                setTotalRows(response.count)
            }
        }
    }, [userToken, paginationModel, searchText]);
    React.useEffect(() => {
        UpdateList().then();
    }, [userToken, paginationModel.page, paginationModel.pageSize, searchText, UpdateList]);

    const [list, setList] = React.useState<IApplicationItem[]>([]);

    const sidebarItems = React.useMemo<SidebarItem[]>(() => {

        let sidebarItems: SidebarItem[] = [

            // {
            //     url: "/",
            //     text: "Applications",
            //     icon: faHome,
            //     mode: PermissionMode.all,
            //     list: []
            // },



        ];

        list.forEach((item) => {
            sidebarItems.push(
                {
                    url: `/applications/${item._id}`,
                    text: `${item.name}`,
                    icon: faPhone,
                    mode: PermissionMode.all,
                    list: []
                },
            )
        })


        return sidebarItems
    }, [list, user, userToken, setUserToken, setAdminUserToken, UpdateList, navigate, setUserAccessToken]);


    // Divider


    return (

            <Paper elevation={1} style={{height: 'calc( var(--app-height) - 0rem - 48px )', padding: '1rem', borderRadius: 0, width: '300px'}}>
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="stretch"
                    spacing={0}
                    style={{height: '100%'}}
                >
                    <ScrollPane>
                        <List dense={false}>


                            {[{
                                url: "/",
                                text: "Applications",
                                icon: faHome,
                                mode: PermissionMode.all,
                                list: []
                            }].map((item, index) => {
                                return (
                                    <SideBarItemView key={index} item={item} user={user!}
                                                     saveRequired={saveRequired ?? false}/>
                                )
                            })}

                            {/*{totalRows}*/}

                            <hr style={{opacity: 0.1}}/>

                            {sidebarItems.map((item, index) => {
                                return (
                                    <SideBarItemView key={index} item={item} user={user!}
                                                     saveRequired={saveRequired ?? false}/>
                                )
                            })}


                            {totalRows > paginationModel.pageSize && (<>

                                <Stack direction={"row"}>

                                    <Button><FontAwesomeIcon icon={faBackward}/></Button>
                                    <Typography flexGrow={1}
                                                sx={{textAlign: 'center'}}>{paginationModel.page + 1}</Typography>
                                    <Button><FontAwesomeIcon icon={faForward}/></Button>


                                </Stack>

                                {/*Buttons*/}

                            </>)}


                            <hr style={{opacity: 0.1}}/>


                            {[
                                {
                                    url: "/dashboard/profile",
                                    text: "Change Password",
                                    icon: faUserCircle,
                                    mode: PermissionMode.all,
                                    list: []
                                },
                                {
                                    url: "/dashboard/sessions",
                                    text: "Sessions",
                                    icon: faUserCircle,
                                    mode: PermissionMode.all,
                                    list: []
                                },
                                // {
                                //     url: "/dashboard/notifications",
                                //     text: "Notification",
                                //     icon: faHome,
                                //     mode: PermissionMode.allowList,
                                //     list: [UserRole.ADMIN]
                                // },

                                {
                                    url: "/dashboard/sentEmails",
                                    text: "Sent Emails",
                                    icon: faHome,
                                    mode: PermissionMode.allowList,
                                    list: [UserRole.ADMIN]
                                },
                                // {
                                //     url: "/dashboard/importUsers",
                                //     text: "Bulk Import Users",
                                //     icon: faHome,
                                //     mode: PermissionMode.allowList,
                                //     list: [UserRole.ADMIN]
                                // },
                                {
                                    url: "/dashboard/users",
                                    text: "Users",
                                    icon: faUsers,
                                    mode: PermissionMode.allowList,
                                    list: [UserRole.ADMIN]
                                },
                                {
                                    url: "/dashboard/userDashboard",
                                    text: "User Dashboard",
                                    icon: faUsers,
                                    mode: PermissionMode.allowList,
                                    list: [UserRole.ADMIN]
                                },

                            ].map((item, index) => {
                                return (
                                    <SideBarItemView key={index} item={item} user={user!}
                                                     saveRequired={saveRequired ?? false}/>
                                )
                            })}


                            {/*{user !== undefined && user !== null && user.role !== undefined && (user.role.includes(UserRole.ADMIN) || user.role.includes(UserRole.USER_MANAGER)) && (<>*/}
                            {/*    <hr style={{ opacity: 0.1 }} />*/}
                            {/*</>)}*/}


                        </List>
                    </ScrollPane>

                    <List dense={false}>
                        <hr style={{ opacity: 0.1 }} />

                        {adminUserToken !== null && adminUserToken !== undefined && (<>
                            <ListItemButton
                                disabled={saveRequired === true}
                                onClick={async () => {
                                    if (userAccessToken === null || userAccessToken === undefined) {
                                        return
                                    }
                                    await new LogoutController(userAccessToken).logout();
                                    // TODO: send network request to close the session.
                                    setUserToken(adminUserToken);
                                    setAdminUserToken(null);
                                }}
                            >
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Back to Admin"
                                />
                            </ListItemButton>
                        </>)}

                        <ListItemButton onClick={() => {
                            async function logout() {
                                if (userAccessToken === null || userAccessToken === undefined) {
                                    return
                                }
                                await new LogoutController(userAccessToken).logout();

                                if (adminUserToken !== null && adminUserToken !== undefined) {
                                    await new LogoutController(adminUserToken).logout();
                                    setAdminUserToken(null);
                                }

                                setUserToken(null);
                                setUserAccessToken(null);
                                setUserTokenExpiration(null);
                                setUserRefreshToken(null);
                            }
                            // this didn't seem to work.

                            logout().then(() => {});
                        }}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Logout"
                            />
                        </ListItemButton>

                        <ListItem style={{ textAlign: "center", display: "block", opacity: 0.5 }}>
                            {(user !== undefined && user !== null && user.role === UserRole.ADMIN) && (<>
                                <Typography variant={'caption'} style={{ textAlign: "center", display: "block", opacity: 0.5 }}>Role: { user.role }</Typography>
                            </>)}
                            <Typography variant={'caption'} style={{ textAlign: "center", display: "block", opacity: 0.5 }}>Version 0.0.1</Typography>
                            <Typography variant={'caption'} style={{ textAlign: "center", display: "block", opacity: 0.5 }}>COPYRIGHT &copy; { new Date().getFullYear() }</Typography>
                        </ListItem>
                    </List>
                </Stack>
            </Paper>

        // </ThemeProvider>
    );
}

export default Sidebar;
