import React from "react";

function ScrollPane(props: any) {
    return (
        <div className="scroll-pane">
            {props.children}
        </div>
    )
}

export default ScrollPane;