import {CustomFormField} from "../../types/interfaces";
import {IconButton, InputAdornment, TextField} from "@mui/material";
import React from "react";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";

export function EditText<T>(props: { item: T, setItem: any, field: CustomFormField }) {

    let endAdornment = props.field.toggleIncluded ? (<InputAdornment position="end">
        <IconButton
            aria-label="Toggle Visibility"
            onClick={() => {
                let newItem = {...props.item};
                newItem[props.field.toggleIncluded?.key as keyof T] = (!newItem[props.field.toggleIncluded?.key as keyof T]) as unknown as T[keyof T];
                props.setItem(newItem);
            }}
            edge="end"
            disabled={!props.field.toggleIncluded.editable || props.item[props.field.key as keyof T] === ''}
        >
            {(props.item[props.field.toggleIncluded?.key as keyof T] === true) ? <ToggleOnIcon style={{ color: 'green' }} /> : <ToggleOffIcon />}
        </IconButton>
    </InputAdornment>) : (props.field.endAdornment || undefined);


    return (<>
        <TextField
            fullWidth={true}
            type={props.field.kind}
            label={props.field.label}
            value={props.item[props.field.key as keyof T]}
            onChange={(e) => {
                let newItem = {...props.item};
                newItem[props.field.key as keyof T] = e.target.value as unknown as T[keyof T];
                props.setItem(newItem);
            }}
            disabled={props.field.readonly}
            InputProps={{
                endAdornment: endAdornment,
            }}
            InputLabelProps={{ shrink: (props.item[props.field.key as keyof T] !== '' && props.item[props.field.key as keyof T] !== null && props.item[props.field.key as keyof T] !== undefined) }}
        ></TextField>
    </>)
}