import {CustomFormField} from "../../types/interfaces";
import React from "react";
import moment from "moment";
import {DateTimePicker} from "@mui/x-date-pickers";

export function EditDateTimeWOZone<T>(props: { item: T, setItem: any, field: CustomFormField }) {

    let date = props.item[props.field.key as keyof T] as unknown as Date;

    return (
        <>
            {/*<DateTimePicker*/}
            {/*    label={props.field.label}*/}
            {/*    value={ date === null || date === undefined ? null : moment(date) }*/}
            {/*    onChange={(date) => {*/}
            {/*        let newItem = { ...props.item };*/}
            {/*        newItem[props.field.key as keyof T] = date as unknown as T[keyof T];*/}
            {/*        props.setItem(newItem);*/}
            {/*    }}*/}
            {/*    slotProps={{*/}
            {/*        textField: {*/}
            {/*            fullWidth: true,*/}
            {/*        },*/}
            {/*    }}*/}
            {/*    disabled={props.field.readonly}*/}
            {/*/>*/}

            <DateTimePicker
                label={props.field.label}
                value={date ? moment(date) : null} // Convert to UTC and format
                onChange={(date) => {
                    let newItem = { ...props.item };
                    // Assuming 'date' is a moment object. If not, create one with moment.utc(date)
                    newItem[props.field.key as keyof T] = moment.utc(date).format('YYYY-MM-DDTHH:mm:ss') as unknown as T[keyof T]; // Convert back to UTC ISO string for storage
                    props.setItem(newItem);
                }}
                slotProps={{
                    textField: {
                        fullWidth: true,
                    },
                }}
                disabled={props.field.readonly}
            />

        </>
    );
}
