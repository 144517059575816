import {Alert, Box, Button, ButtonGroup, Grid, Modal, Paper, Stack, TextField, Typography} from "@mui/material";
import React from "react";
import AuthenticatedNavBar from "./AuthenticatedNavBar";
import Sidebar from "./Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faPlus, faSave, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";
import {SidebarContext} from "./SidebarContext";
import {AuthContext} from "./AuthContext";
import moment from "moment";

export interface AuthenticatedLayoutCustomButton {
    action: () => void,
    icon: any,
    label: string,
    disabled?: boolean,
    color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined
}
export interface AuthenticatedLayoutProps {
    pageTitle?: string | undefined,
    onAddClicked?: (() => void) | undefined,
    customAddIcon?: React.ReactNode | undefined,
    onOpenCustomizer?: (() => void) | undefined,
    onSaveClicked?: (() => void) | undefined,
    saveRequired?: boolean | undefined,
    backLink?: string | undefined,
    customButtons?: AuthenticatedLayoutCustomButton[],
    searchText?: {text: string, setText: any, label: string},
    children: React.ReactNode | React.ReactNodeArray,
}

function ButtonBoard(props: AuthenticatedLayoutProps) {
    const {
        pageTitle,
        onAddClicked,
        customAddIcon,
        onOpenCustomizer,
        onSaveClicked,
        backLink,
        searchText,
        customButtons
    } = props;

    const navigate = useNavigate();

    const buttonArrayMemo = React.useMemo(() => {
        let buttonArray = customButtons ?? [];
        if (onAddClicked !== undefined) {
            buttonArray.push({
                action: onAddClicked,
                icon: customAddIcon !== undefined ? customAddIcon : <FontAwesomeIcon icon={faPlus} />,
                label: "Add"
            })
        }

        if (onOpenCustomizer !== undefined) {
            buttonArray.push({
                action: onOpenCustomizer,
                icon: <FontAwesomeIcon icon={faCog} />,
                label: "Customize"
            })
        }

        if (onSaveClicked !== undefined) {
            buttonArray.push({
                action: onSaveClicked,
                icon: <FontAwesomeIcon icon={faSave} />,
                label: "Save"
            })
        }

        if (backLink !== undefined) {
            buttonArray.push({
                action: () => {navigate(backLink)},
                icon: <FontAwesomeIcon icon={faArrowLeft} />,
                label: "Back"
            })
        }

        return buttonArray;
    }, [
        customButtons,
        onAddClicked,
        customAddIcon,
        onOpenCustomizer,
        onSaveClicked,
        backLink,
        navigate,
    ]);

    const MaxButtonSizeMemo = React.useMemo(() => {
        return buttonArrayMemo.length > 4 ? 4 : buttonArrayMemo.length;
    }, [buttonArrayMemo.length]);

    const SpacerSizeMemo = React.useMemo(() => {
        return 8 - MaxButtonSizeMemo - (searchText !== undefined ? 4 : 0)
    } , [searchText, MaxButtonSizeMemo]);

    const ButtonContainerSizeMemo = React.useMemo(() => {
        return MaxButtonSizeMemo;
    }, [MaxButtonSizeMemo])

    return (<>

        {backLink !== undefined && (
            <Grid item xs={1}>
                <ButtonGroup size={"large"} fullWidth={true} variant="contained">
                    <Button onClick={() => {navigate(backLink)}}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </Button>
                </ButtonGroup>
            </Grid>

        )}
        <Grid item xs={4}>
            <Typography sx={{ px: backLink !== undefined ? 2 : 0, verticalAlign: 'center' }} variant={"h5"}>{pageTitle}</Typography>
        </Grid>



        {searchText !== undefined && (<>
            <Grid item xs={SpacerSizeMemo / 2}></Grid>
            <Grid item xs={3}>
                <TextField fullWidth={true} label={searchText.label} size={"small"} variant="outlined" value={searchText.text} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    searchText.setText(event.target.value);
                } }  />
            </Grid>
            <Grid item xs={SpacerSizeMemo / 2}></Grid>
        </>)}

        {searchText === undefined && (<>
            <Grid item xs={SpacerSizeMemo}></Grid>
        </>)}

        {ButtonContainerSizeMemo > 0 && (<>
            <Grid item xs={ButtonContainerSizeMemo}>
                <ButtonGroup size={"large"} fullWidth={true} variant="contained">
                    {buttonArrayMemo.map((button, index) => {
                        return (
                            <Button size={"large"} onClick={button.action} key={index} disabled={ button.disabled } color={button.color ?? "primary"}>
                                {button.icon}&nbsp;{button.label}
                            </Button>
                        )
                    })}
                </ButtonGroup>
            </Grid>
        </>)}
    </>)
}

function ReauthenticateModalAndBackdrop() {


    const {
        setUserToken,
        setUserAccessToken,
        setUserRefreshToken,
        setUserTokenExpiration,
        userTokenExpiration
    } = React.useContext(AuthContext)!;

    const [tokenExpired, setTokenExpired] = React.useState<boolean>(false);

    React.useEffect(() => {
        const checkTokenExpiration = () => {
            if (userTokenExpiration !== null && moment(userTokenExpiration).isBefore(moment())) {
                setTokenExpired(true);
            }
        };

        // Run the initial check when the component mounts
        checkTokenExpiration();

        // Set up an interval to check the token expiration every second
        const intervalId = setInterval(checkTokenExpiration, 1000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [userTokenExpiration]);


    const [emailAddress, setEmailAddress] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    function onEnterKeyUpLogin(event: any) {
        if (event.key === 'Enter'){
            if (emailAddress === undefined || emailAddress === "") return setErrorMessage("Invalid Email Address");
            if (password === undefined || password === "") return setErrorMessage("Invalid Password");
            Login(emailAddress, password).then(() => {});
        }
    }

    async function Login(email_address: string, password: string) {

        try {
            const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
            // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;

            // TODO: finish this, it's not sending the object yet.
            let results = await fetch(`http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}/api/auth/login`, {
                method: "POST",
                headers: {
                    // "authorization": `Bearer ${this.userToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email_address,
                    password
                })
            });
            let data = await results.json();
            console.log(data);
            // let { token } = data;
            let { accessToken, refreshToken, expirationDate } = data;
            if (accessToken === undefined || refreshToken === undefined || expirationDate === undefined) {
                return setErrorMessage("Invalid Credentials")
            }

            setTimeout(() => {
                setErrorMessage(undefined);

                setUserToken(accessToken);
                setUserAccessToken(accessToken);
                setUserRefreshToken(refreshToken);
                setUserTokenExpiration(expirationDate);

                setTokenExpired(false);
            }, 1000);

        }
        catch (e) {
            console.log(e);
            setErrorMessage("Invalid Credentials");
        }
    }

    return (<>
        <Modal
            open={tokenExpired}
            onClose={() => {  }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onBackdropClick={() => {}}
        >
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4
            }}>
                <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    Reauthenticate
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField fullWidth={true} label="Email Address" variant="outlined" type="email" value={emailAddress} autoComplete={"UserName"} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEmailAddress(event.target.value);
                        }}  />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth={true} label="Password" variant="outlined" type="password" autoComplete={"password"} value={password} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(event.target.value);
                        }} onKeyUp={onEnterKeyUpLogin} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                            if (emailAddress === undefined || emailAddress === "") return setErrorMessage("Invalid Email Address");
                            if (password === undefined || password === "") return setErrorMessage("Invalid Password");
                            setErrorMessage(undefined);
                            Login(emailAddress, password).then(() => {});
                        }}>Login</Button>
                    </Grid>

                    {errorMessage !== undefined && (
                        <Grid item xs={12}>
                            <Alert severity="error">Invalid Login</Alert>
                        </Grid>
                    )}
                </Grid>
            </Box>
        </Modal>
    </>);


}

function AuthenticatedLayout(props: AuthenticatedLayoutProps) {
    const {
        pageTitle,
        saveRequired
    } = props;

    const {
        showSidebar,
        setShowSidebar
    } = React.useContext(SidebarContext);

    return (
        <div>
            <AuthenticatedNavBar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

            <Stack spacing={2} direction={'row'} style={{ width: '100%' }}>

                {showSidebar && (<Sidebar saveRequired={saveRequired} />)}

                <Stack
                    direction="column"
                    justifyContent="flexStart"
                    alignItems="stretch"
                    spacing={2}
                    style={{
                        padding: '1rem',
                        height: 'calc( var(--app-height) - 0rem - 48px )',
                        width: showSidebar ? 'calc( 100% - 300px )' : 'calc( 100% )',
                    }}
                >
                    {pageTitle !== undefined && (
                        <Paper elevation={1} style={{
                            width:"calc ( 100% - 2rem )",
                            padding: '1rem',
                            paddingBottom: '0.5rem',
                            minHeight: '5rem',
                            height: '5rem',
                        }}>
                            <Grid container spacing={1} alignItems="center" style={{ height: '100%' }}>
                                <ButtonBoard {...props} children={[]} />
                            </Grid>
                        </Paper>
                    )}
                    <Paper elevation={1} style={{ width:"calc ( 100% - 2rem )", padding: '1rem', flexGrow: 1, height: 'calc( var(--app-height) - 8rem - 48px )', }}>
                        {props.children}
                    </Paper>
                </Stack>
            </Stack>
            <ReauthenticateModalAndBackdrop />
        </div>
    );
}

export default AuthenticatedLayout;
