import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "./enums";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import { ComponentType } from "react";

export interface CustomFormFieldOption {
    label: string;
    value: any;
}

export interface CustomFormField {
    key: string;
    label: string;
    kind: CustomFormFieldTypes; // can't use key "type"
    size: CustomFormFieldSizes;
    options?: CustomFormFieldOption[];
    multilineRows?: number;
    // options?: any; // this is where multiple line number goes.
    readonly?: boolean;
    endAdornment?: any;
    toggleIncluded? : { key: string, editable: boolean};
    passthru?: ComponentType<any>; // replace this with a react component.
    passthruProps?: any;
    subform?: SubForm;
}

export interface SubForm {
    fields?: CustomFormField[]
    columns?: GridColDef[]
}

// export interface CustomColumn {
//     index: number;
//     key: string;
//     width: number;
//     enabled: boolean;
// }

export interface DataController<T> {
    // data: any[]
    getAll: () => Promise<T[]>
    getOne: (id: string) => Promise<T>
    create: (item: T) => Promise<T>
    update: (id: string, item: T) => Promise<T>
}

export interface IUser {

    _id: string;
    name: string;
    email_address: string;
    phone_number: string;
    // password_hash: string;
    role: UserRole;
    // iOSDeviceTokens: string[];
    // androidDeviceTokens: string[];
    // lastTokenRefresh?: Date;team_id
    userAgentLog?: string[];
    isDeactivated?: boolean;
    activeSessions: ISession[];
    team_id?: string | ITeam | undefined;
}

export interface ISession {
    _id: string;
    user: IUser;
    userAgent: string;
    ipAddress: string;
    environment: "production" | "sandbox"; // this is for push notifications
    iOSDeviceToken?: string;
    androidDeviceToken?: string;
}



export interface ISentEmail {
    to: string;
    bcc?: string;
    cc?: string;
    subject: string;
    text?: string;
    html?: string;
    attachmentCount?: number;
    confirmation?: string;
}


// APPLICATION
export interface IApplicationItem {
    _id: string | undefined;
    name: string;
    apple_id: string;
    team_id: string; // | ITeam;
}


export interface ITeam {
    _id: string; // does this mess with mongoose...?
    name: string;
    created_by: string | IUser;

    // iOSDeviceTokens: string[];
}

export interface IApplicationUser {
    _id: string;
    // user_unique_id: string;
    // user_properties: {[key: string]: string };
    // user_properties: Dictionary<string, string>;
    user_properties: Map<string, string>;
    application_id: string | IApplicationItem;
}

export interface IApplicationEvent {
    _id: string;
    eventName: string;
    parameters: Map<string, string>;
    timestamp: Date;
    application_id: string | IApplicationItem;
    application_user_id: string | IApplicationUser;
}

export enum WebSocketAction {
    EVENT = "EVENT",
    NOOP = "NOOP",
    ERROR = "ERROR",
    ApplicationUserUpdated = "ApplicationUserUpdated",
    ApplicationUserDeviceUpdated = "ApplicationUserDeviceUpdated"
}

export interface WebSocketMessage {
    action: WebSocketAction;
    // table?: "InventoryItem";
    // id?: string;
    data: any;
}