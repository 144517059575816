// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {CustomFormField} from "../../types/interfaces";
import {AuthContext} from "../../Library/AuthContext";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../../types/enums";
import {EditObject} from "../../Shared/Components/EditObject";
import {Button, Stack} from "@mui/material";
// https://mui.com/material-ui/icons/#font-awesome

export class ChangePasswordController {
    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }

    public async update(currentPassword: string, password: string): Promise<boolean> {
        let results = await fetch(`${this.baseURL}/api/me/password`, {
            method: "PUT",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({currentPassword, password})
        });

        // handle when results is 304
        if (results.status !== 200) {
            console.log("304");
            return false;
        }

        return true;
    }
}

function ProfileView() {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;

    const [passwordFields , setPasswordFields] = React.useState<any>({});

    let fields: CustomFormField[] = [
        {
            kind: CustomFormFieldTypes.PASSWORD,
            size: CustomFormFieldSizes.FULL,
            key: "currentPassword",
            label: "Current Password",
        },
        {
            kind: CustomFormFieldTypes.PASSWORD,
            size: CustomFormFieldSizes.FULL,
            key: "password",
            label: "Password",
        },
        {
            kind: CustomFormFieldTypes.PASSWORD,
            size: CustomFormFieldSizes.FULL,
            key: "confirm_password",
            label: "Confirm Password",
        },
    ]

    const handleSave = () => {
        // console.log(`userToken: ${userToken} passwordFields: ${passwordFields}`)
        async function saveData() {
            // console.log(`userToken: ${userToken} passwordFields: ${passwordFields}`)
            if (userToken === null || userToken === undefined) {
                console.log("userToken is null")
                return;
            }

            if (passwordFields.currentPassword === undefined) {
                return;
            }

            if (passwordFields.password === undefined || passwordFields.confirm_password === undefined) {
                console.log("passwordFields is null")
                return;
            }
            // check empty string
            if (passwordFields.password === "" || passwordFields.confirm_password === "") {
                console.log("passwordFields is empty")
                return;
            }
            if (passwordFields.password !== passwordFields.confirm_password) {
                console.log("passwordFields is not equal")
                return;
            }


            try {
                const changePasswordController = new ChangePasswordController(userToken);

                let success = await changePasswordController.update(passwordFields.currentPassword, passwordFields.password);

                if (!success) {
                    alert("Failed to save");
                    return;
                }
                alert("Saved");
            }
            catch (e) {
                console.warn(`exception: ${(e as any).message}`)
            }
        }

        saveData().then();
    }

    const nextDisabled = React.useMemo(() => {
        if (passwordFields.password === undefined || passwordFields.confirm_password === undefined) {
            return true;
        }
        if (passwordFields.password !== passwordFields.confirm_password) {
            return true;
        }
        return false;
    }, [passwordFields]);

    return (
        <div>
            <AuthenticatedLayout pageTitle={"Change Password"}>
                <Stack spacing={2} style={{ height: '100%' }}>
                    <EditObject item={passwordFields} setItem={setPasswordFields} form={fields} columns={5}></EditObject>
                    <Button fullWidth={true} onClick={() => handleSave()} disabled={nextDisabled} variant={"contained"}>
                        Save
                    </Button>
                </Stack>
            </AuthenticatedLayout>
        </div>
    );
}

export default ProfileView;