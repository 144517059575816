import React from "react";
import {Paper} from "@mui/material";

function DebugView(props: any) {
  return (
      <div className="debug-pane">
        <Paper elevation={2} style={{margin: '1rem', padding: '1rem'}}>
                <pre>
                    {JSON.stringify(props.value, null, 2)}
                </pre>
        </Paper>

      </div>
  )
}

export default DebugView;