// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
// import {
//     DataGrid,
//     GridColDef, GridRowModel,
//     GridValidRowModel
// } from '@mui/x-data-grid';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {
    IApplicationEvent,
    IApplicationItem,
    IApplicationUser,
    IUser, WebSocketAction,
    WebSocketMessage
} from "../../types/interfaces";

// import {ChangeUserController, ResendNewUserEmailController, UserDataController} from "./UserDataController";
import {AuthContext} from "../../Library/AuthContext";
import {CustomFormFieldSizes, CustomFormFieldTypes, DEFAULT_PAGE_SIZE_OPTIONS, UserRole} from "../../types/enums";
import {
    Box,
    Button,
    FormControl,
    FormControlLabel, InputLabel, MenuItem, Modal, Paper,
    Select,
    Stack,
    Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography
} from "@mui/material";
import moment from 'moment';

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';

import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import CircleIcon from '@mui/icons-material/Circle'

import {useNavigate, useParams} from "react-router-dom";
import {useLocalStorage} from "../../Hooks/useLocalStorage";
import {IPaginated, PaginatedInput} from "../Users/UserDataController";
import {EditObject} from "../../Shared/Components/EditObject";
import { DatePicker } from '@mui/x-date-pickers';
import useWebSocket from 'react-use-websocket';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DebugView from "../../Library/DebugView";

const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000"}`;

export class ApplicationEventController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }



    // public async create(item: IApplicationItem): Promise<IApplicationItem> {
    //     console.log(item);
    //     let results = await fetch(`${this.baseURL}/api/applicationUser`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check.
    // }

    public async get(application_user_id: string, selectedDate: Date): Promise<IApplicationEvent[]> {
        // let dateString = JSON.stringify(selectedDate)
        let dateString = moment(selectedDate).toISOString();
        let url = `${this.baseURL}/api/analyticsEvent/${application_user_id}/${dateString}`;
        console.log(url);
        let results = await fetch(url, {
            method: "GET",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            // body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data as IApplicationEvent[]; // this doesn't seem to type check.
    }

    // public async get(filter: PaginatedInput, searchText: String): Promise<IPaginated<IApplicationItem>> {
    //     let results = await fetch(`${this.baseURL}/api/applications/?page=${filter.page}&limit=${filter.pageSize}&filter=${searchText}`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

}

export class ApplicationUserController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }



    // public async create(item: IApplicationItem): Promise<IApplicationItem> {
    //     console.log(item);
    //     let results = await fetch(`${this.baseURL}/api/applicationUser`, {
    //         method: "POST",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         body: JSON.stringify(item)
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check.
    // }

    public async get(application_id: string): Promise<IApplicationUser[]> {
        let results = await fetch(`${this.baseURL}/api/applicationUser/${application_id}`, {
            method: "GET",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            // body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check.
    }

    // public async get(filter: PaginatedInput, searchText: String): Promise<IPaginated<IApplicationItem>> {
    //     let results = await fetch(`${this.baseURL}/api/applications/?page=${filter.page}&limit=${filter.pageSize}&filter=${searchText}`, {
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`
    //         }
    //     });
    //     let data = await results.json();
    //     return await data; // this doesn't seem to type check.
    // }

}


export class ApplicationItemController {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }



    public async create(item: IApplicationItem): Promise<IApplicationItem> {
        console.log(item);
        let results = await fetch(`${this.baseURL}/api/applications`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check.
    }

    // public async get(): Promise<IApplicationItem[]> {
    //     let results = await fetch(`${this.baseURL}/api/applications`, {
    //         method: "GET",
    //         headers: {
    //             "authorization": `Bearer ${this.userToken}`,
    //             "Content-Type": "application/json"
    //         },
    //         // body: JSON.stringify(item)
    //     });
    //     let data = await results.json();
    //     console.log(data);
    //     return await data; // this doesn't seem to type check.
    // }

    public async get(filter: PaginatedInput, searchText: String): Promise<IPaginated<IApplicationItem>> {
        let results = await fetch(`${this.baseURL}/api/applications/?page=${filter.page}&limit=${filter.pageSize}&filter=${searchText}`, {
            headers: {
                "authorization": `Bearer ${this.userToken}`
            }
        });
        let data = await results.json();
        return await data; // this doesn't seem to type check.
    }

}

function ApplicationEventView() {

    const {
        userToken,
    } = React.useContext(AuthContext)!;

    const { id : application_id } = useParams<{ id: string }>();

    // TODO: determine how to handle pagination with a flowing change of events, and a timeline.
    // TODO: make a timeline view that I can display the events in a nice graphical way.

    // TODO: this doesn't work correctly.
    const [selectedDate, setSelectedDate] = React.useState<Date | null>( null );
    const [selectedUserID, setSelectedUserID] = React.useState<string | null>(null);
    const [userIDList, setUserIDList] = React.useState<IApplicationUser[]>([]);

    const UpdateUserList = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        if (application_id === null || application_id === undefined){
            return;
        }
        let response = await new ApplicationUserController(userToken).get(application_id);
        console.log(response)
        if (response !== null && response !== undefined) {
            setUserIDList(response)
            // if (response !== undefined && response !== undefined){
            //
            // }
        }
    }, [userToken, application_id]);

    React.useEffect(() => {
        UpdateUserList().then();
    }, [userToken, UpdateUserList]);


    const UpdateEventList = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        if (selectedDate === null || selectedUserID === null){
            setList([])
            return
        }
        let response = await new ApplicationEventController(userToken).get(selectedUserID, selectedDate);
        console.log(response)
        if (response !== null && response !== undefined) {
            setList(response)
            // if (response.items !== undefined && response.count !== undefined){
            //     setList(response.items);
            //     setTotalRows(response.count)
            // }
        }
    }, [userToken, selectedUserID, selectedDate]);

    React.useEffect(() => {
        UpdateEventList().then();
    }, [userToken, selectedUserID, selectedDate]);

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    });
    const [totalRows, setTotalRows] = React.useState<number>(0);
    // const [searchText, setSearchText] = useLocalStorage("ApplicationManagementView.searchText", "") as [string, React.Dispatch<React.SetStateAction<string>>];

    //
    // const UpdateList = React.useCallback(async () => {
    //     if (userToken === null || userToken === undefined) {
    //         return;
    //     }
    //     let response = await new ApplicationItemController(userToken).get(paginationModel, searchText);
    //     console.log(response)
    //     if (response !== null && response !== undefined) {
    //         if (response.items !== undefined && response.count !== undefined){
    //             setList(response.items);
    //             setTotalRows(response.count)
    //         }
    //     }
    // }, [userToken, paginationModel, searchText]);
    //
    // const columns: GridColDef[] = [
    //     { field :'name', headerName: 'Name', width: 250, editable: false },
    // ]
    //
    // React.useEffect(() => {
    //     UpdateList().then();
    // }, [userToken, paginationModel.page, paginationModel.pageSize, searchText, UpdateList]);

    const [list, setList] = React.useState<IApplicationEvent[]>([]);

    const processMessages = React.useCallback((event: WebSocketEventMap['message']) => {

        console.log("Received message: ", event.data);
        let data = JSON.parse(event.data) as WebSocketMessage;
        console.log("Received message: ", data);
        switch (data.action) {
            case WebSocketAction.EVENT:
                // TODO: This is adding it no matter what!
                let appEvent = data.data as IApplicationEvent;
                if (selectedDate === null) {
                    return;
                }
                const endDate = new Date(selectedDate);
                endDate.setDate(endDate.getDate() + 1);
                if (
                    appEvent.application_user_id === selectedUserID &&
                    moment(appEvent.timestamp).isBetween(selectedDate, endDate, undefined, '[]')
                ) {
                    setList([...list, appEvent])
                }
                break;
            case WebSocketAction.ApplicationUserUpdated:
                let updatedUser = data.data as IApplicationUser;
                let updatedList = userIDList.map((m) => {
                    if (m._id === updatedUser._id) {
                        return updatedUser;
                    }
                    else {
                        return m
                    }
                })
                if (updatedList.indexOf(updatedUser) === -1) {
                    updatedList.push(updatedUser)
                }
                setUserIDList(updatedList);
                break;
            case WebSocketAction.ApplicationUserDeviceUpdated:
                // TODO: deal with this
                // let updatedUser = data.data as IApplicationUser;
                // let updatedList = userIDList.map((m) => {
                //     if (m._id === updatedUser._id) {
                //         return updatedUser;
                //     }
                //     else {
                //         return m
                //     }
                // })
                // if (updatedList.indexOf(updatedUser) === -1) {
                //     updatedList.push(updatedUser)
                // }
                // setUserIDList(updatedList);
                break;

            case WebSocketAction.NOOP:
                console.error("NOOP WebSocket Action." +
                    "");
                break;
            case WebSocketAction.ERROR:
                console.error("Error: ", data);
                break;
        }
    }, [userToken, list, setList]);


    const userPropertiesMemo = React.useMemo<[string, string][]>(() => {
        let selectedUser = userIDList.find(d => d._id === selectedUserID);
        if (selectedUser === undefined) { return []; }
        if (selectedUser === null) { return []; }
        if (selectedUser.user_properties === undefined) { return []; }
        if (selectedUser.user_properties === null) { return []; }
        var temp: [string, string] [] = [];

        let test = Object.entries(selectedUser.user_properties);
        test.forEach((val, index) => {
            let key = val[0];
            let value = val[1];
            temp.push([key, value])
        });

        // let keys = Object.keys(selectedUser.user_properties);
        // keys.forEach((key) => {
        //
        //     let vals = Object.values(selectedUser.user_properties);
        //     console.log(vals);
        //     // let val = Object.values(selectedUser.user_properties)[key];
        //     let val = selectedUser?.user_properties.get(key) ?? ""
        //     temp.push([key, val])
        // })
        // console.log(keys);

        // user_properties?.forEach( (v, k) => {
        //     console.log("k=" +k + " v="+v);
        //     temp.push([k, v]);
        // })

        // Object.keys(user_properties).map( key => {
        //     console.log("key: " + key);
        //     let value = user_properties.get(key) ?? "";
        //     // console.log("value: " + user_properties[key]);
        //
        // });

        // for (const [key, value] of user_properties) {
        //     console.log(`Key: ${key}, Value: ${value}`);
        //     temp.push([key, value])
        // }

        // for (let i in user_properties) {
        //     // console.log(map[i]);
        //
        //     temp.push([i, user_properties[i]])
        //
        //     // temp.push([ k, user_properties.get(k) as string ])
        //
        // }

        // keys.forEach((k) => {
        //     temp.push([ k, user_properties.get(k) as string ])
        // })
        // selectedUser.user_properties.forEach((x, y) => {
        //     temp.push([x, y]);
        // });
        return temp;
        // return Array.from(selectedUser.user_properties.entries())

        // // let keys = selectedUser.user_properties.keys();
        // return Array(keys).map((key) => selectedUser?.user_properties[key]);
        // return [];
    }, [selectedUserID, userIDList]);


    const { sendJsonMessage/*, getWebSocket*/ } = useWebSocket(WS_URL + '/?token=' + encodeURIComponent(userToken ?? ""), {
        onOpen: () => console.log('WebSocket connection opened.'),
        onClose: () => console.log('WebSocket connection closed.'),
        shouldReconnect: (closeEvent) => true,
        onMessage: (event: WebSocketEventMap['message']) =>  processMessages(event)
    });

    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"App Events"}
                                  // searchText={{text: searchText, setText: setSearchText, label: "Search (Name)"}}
            >
                <Stack style={{ height: 'calc( 100% - 4rem )' }} direction={"column"}>
                    <Stack gap={2} direction={"row"}>



                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Application User</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectedUserID}
                                label="Application User"
                                onChange={(v) => {
                                    setSelectedUserID(v.target.value as string)
                                }}
                            >
                                {userIDList.map((u) => {
                                    return ( <MenuItem value={u._id}>{u._id}</MenuItem>);
                                })}
                            </Select>
                        </FormControl>

                        <DatePicker
                            label="Date"
                            value={selectedDate}
                            onChange={(newValue) => setSelectedDate(newValue)}
                            // openTo="month"
                            // views={['year', 'month', 'day']}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                },
                            }}
                        />
                    </Stack>

                    {/*{selectedUserID !== null && selectedDate !== null && (<>*/}
                        <Stack style={{ height: 'calc( 100% )', paddingTop: '1rem' }} gap={2} direction={"row"}>


                            {selectedUserID !== null && selectedDate !== null && (<>
                            <div style={{ overflowY: 'scroll', height: 'calc( 100% )', flexGrow: 1 }}>


                                <Timeline position="right">

                                    {list.map((item) => {
                                        return (<>
                                            <TimelineItem>
                                                <TimelineOppositeContent
                                                    sx={{ m: 'auto 0' }}
                                                    align="right"
                                                    variant="body2"
                                                    color="text.secondary"
                                                >
                                                    { new Date(item.timestamp).toLocaleTimeString() }
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineConnector />
                                                    <TimelineDot color={"primary"}>
                                                        {/*<CircleIcon />*/}
                                                        <FontAwesomeIcon icon={"money-bill"} />
                                                    </TimelineDot>
                                                    <TimelineConnector />
                                                    {/*<TimelineConnector sx={{opacity: 0.5}} />*/}
                                                </TimelineSeparator>
                                                <TimelineContent sx={{ m: 'auto 0' }}>
                                                    {/*<TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                                    <Typography variant="h6" component="span">
                                                        {item.eventName}
                                                    </Typography>
                                                    {/*<Typography>Parameters: {item.parameters}</Typography>*/}
                                                {/*  TODO: make a hover here that shows the parameters in a tooltip, but then the count of parameters as the hover target  */}
                                                </TimelineContent>
                                            </TimelineItem>
                                        </>);
                                    })}









                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineOppositeContent*/}
                                    {/*        sx={{ m: 'auto 0' }}*/}
                                    {/*        align="right"*/}
                                    {/*        variant="body2"*/}
                                    {/*        color="text.secondary"*/}
                                    {/*    >*/}
                                    {/*        9:30 am*/}
                                    {/*    </TimelineOppositeContent>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        <TimelineDot>*/}
                                    {/*            <FastfoodIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        /!*<TimelineConnector sx={{opacity: 0.5}} />*!/*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Eat*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because you need strength</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}

                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineOppositeContent*/}
                                    {/*        sx={{ m: 'auto 0' }}*/}
                                    {/*        align="right"*/}
                                    {/*        variant="body2"*/}
                                    {/*        color="text.secondary"*/}
                                    {/*    >*/}
                                    {/*        9:30 am*/}
                                    {/*    </TimelineOppositeContent>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        <TimelineDot>*/}
                                    {/*            <FastfoodIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Eat*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because you need strength</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}

                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineOppositeContent*/}
                                    {/*        sx={{ m: 'auto 0' }}*/}
                                    {/*        align="right"*/}
                                    {/*        variant="body2"*/}
                                    {/*        color="text.secondary"*/}
                                    {/*    >*/}
                                    {/*        9:30 am*/}
                                    {/*    </TimelineOppositeContent>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        <TimelineDot>*/}
                                    {/*            <FastfoodIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Eat*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because you need strength</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}

                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineOppositeContent*/}
                                    {/*        sx={{ m: 'auto 0' }}*/}
                                    {/*        align="right"*/}
                                    {/*        variant="body2"*/}
                                    {/*        color="text.secondary"*/}
                                    {/*    >*/}
                                    {/*        9:30 am*/}
                                    {/*    </TimelineOppositeContent>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        <TimelineDot>*/}
                                    {/*            <FastfoodIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Eat*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because you need strength</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}

                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineOppositeContent*/}
                                    {/*        sx={{ m: 'auto 0' }}*/}
                                    {/*        align="right"*/}
                                    {/*        variant="body2"*/}
                                    {/*        color="text.secondary"*/}
                                    {/*    >*/}
                                    {/*        9:30 am*/}
                                    {/*    </TimelineOppositeContent>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        <TimelineDot>*/}
                                    {/*            <FastfoodIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Eat*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because you need strength</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}

                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineOppositeContent*/}
                                    {/*        sx={{ m: 'auto 0' }}*/}
                                    {/*        align="right"*/}
                                    {/*        variant="body2"*/}
                                    {/*        color="text.secondary"*/}
                                    {/*    >*/}
                                    {/*        9:30 am*/}
                                    {/*    </TimelineOppositeContent>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        <TimelineDot>*/}
                                    {/*            <FastfoodIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Eat*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because you need strength</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}

                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineOppositeContent*/}
                                    {/*        sx={{ m: 'auto 0' }}*/}
                                    {/*        align="right"*/}
                                    {/*        variant="body2"*/}
                                    {/*        color="text.secondary"*/}
                                    {/*    >*/}
                                    {/*        9:30 am*/}
                                    {/*    </TimelineOppositeContent>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        <TimelineDot>*/}
                                    {/*            <FastfoodIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Eat*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because you need strength</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}

                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineOppositeContent*/}
                                    {/*        sx={{ m: 'auto 0' }}*/}
                                    {/*        align="right"*/}
                                    {/*        variant="body2"*/}
                                    {/*        color="text.secondary"*/}
                                    {/*    >*/}
                                    {/*        9:30 am*/}
                                    {/*    </TimelineOppositeContent>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        <TimelineDot>*/}
                                    {/*            <FastfoodIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Eat*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because you need strength</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}

                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineOppositeContent*/}
                                    {/*        sx={{ m: 'auto 0' }}*/}
                                    {/*        align="right"*/}
                                    {/*        variant="body2"*/}
                                    {/*        color="text.secondary"*/}
                                    {/*    >*/}
                                    {/*        9:30 am*/}
                                    {/*    </TimelineOppositeContent>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        <TimelineDot>*/}
                                    {/*            <FastfoodIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Eat*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because you need strength</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}

                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineOppositeContent*/}
                                    {/*        sx={{ m: 'auto 0' }}*/}
                                    {/*        align="right"*/}
                                    {/*        variant="body2"*/}
                                    {/*        color="text.secondary"*/}
                                    {/*    >*/}
                                    {/*        9:30 am*/}
                                    {/*    </TimelineOppositeContent>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        <TimelineDot>*/}
                                    {/*            <FastfoodIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Eat*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because you need strength</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}



                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineOppositeContent*/}
                                    {/*        sx={{ m: 'auto 0' }}*/}
                                    {/*        variant="body2"*/}
                                    {/*        color="text.secondary"*/}
                                    {/*    >*/}
                                    {/*        10:00 am*/}
                                    {/*    </TimelineOppositeContent>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        <TimelineDot color="primary">*/}
                                    {/*            <LaptopMacIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Code*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because it&apos;s awesome!</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}
                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*        <TimelineDot color="primary" variant="outlined">*/}
                                    {/*            <HotelIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Sleep*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because you need rest</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}
                                    {/*<TimelineItem>*/}
                                    {/*    <TimelineSeparator>*/}
                                    {/*        <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />*/}
                                    {/*        <TimelineDot color="secondary">*/}
                                    {/*            <RepeatIcon />*/}
                                    {/*        </TimelineDot>*/}
                                    {/*        <TimelineConnector />*/}
                                    {/*    </TimelineSeparator>*/}
                                    {/*    <TimelineContent sx={{ py: '12px', px: 2 }}>*/}
                                    {/*        <Typography variant="h6" component="span">*/}
                                    {/*            Repeat*/}
                                    {/*        </Typography>*/}
                                    {/*        <Typography>Because this is the life you love!</Typography>*/}
                                    {/*    </TimelineContent>*/}
                                    {/*</TimelineItem>*/}




                                </Timeline>

                            </div>
</>)}

                            {selectedDate === null && (<>
                                <div style={{ overflowY: 'scroll', height: 'calc( 100% )', flexGrow: 1 }}>
                                    Select a date.
                                </div>
                            </>)}

                            {selectedUserID !== null && (<>
                            <div style={{ overflowY: 'scroll', height: '100%', width: '30%' }}>

                                <TableContainer component={Paper}>
                                    <Table sx={{  }} aria-label="simple table">
                                        <TableHead >
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: 500 }}>Key</TableCell>
                                                <TableCell align="right" sx={{ fontWeight: 500 }}>Value</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {userPropertiesMemo.map((row) => (
                                                <TableRow
                                                    key={row[0]}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" sx={{ fontWeight: 500 }}>
                                                        {row[0]}
                                                    </TableCell>
                                                    <TableCell align="right">{row[1]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>
                        </>)}


                        </Stack>

                    {/*</>)}*/}

                    {/*{selectedUserID === null || selectedDate === null && (<>Select a user and a date.</>)}*/}

                </Stack>

                {/*<DataGrid*/}
                {/*    // make sure to set height to 100% so that the grid fills the whole container*/}
                {/*    style={{ height: '100%' }}*/}
                {/*    rows={list}*/}
                {/*    columns={columns}*/}
                {/*    getRowId={(row: GridValidRowModel) => row._id}*/}
                {/*    pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}*/}
                {/*    disableRowSelectionOnClick*/}
                {/*    // processRowUpdate={processRowUpdate}*/}
                {/*    // onProcessRowUpdateError={handleProcessRowUpdateError}*/}


                {/*    pagination={true}*/}
                {/*    rowCount={totalRows}*/}
                {/*    paginationMode={"server"}*/}
                {/*    paginationModel={paginationModel}*/}
                {/*    onPaginationModelChange={setPaginationModel}*/}
                {/*/>*/}

            </AuthenticatedLayout>
        </div>
    );
}

export default ApplicationEventView;
