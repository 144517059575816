// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import {
    DataGrid,
    GridColDef, GridRowModel,
    GridValidRowModel
} from '@mui/x-data-grid';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {IUser} from "../../types/interfaces";
import {ChangeUserController, ResendNewUserEmailController, UserDataController} from "./UserDataController";
import {AuthContext} from "../../Library/AuthContext";
import { DEFAULT_PAGE_SIZE_OPTIONS, UserRole} from "../../types/enums";
import {
    Button,
    FormControlLabel,
    Stack,
    Switch,
    Typography
} from "@mui/material";

import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "../../Hooks/useLocalStorage";
import {UserRoleChangeCell} from "./UserRoleChangeCell";
import {UserSessionsModal} from "./UserSessionsModal";
import {AddNewUser} from "./AddNewUser";
// https://mui.com/material-ui/icons/#font-awesome




const ToggleCell = ({ value, onToggle, disabled }: any) => {
    return (
        <FormControlLabel
            control={<Switch checked={value} onChange={(e) => onToggle(e.target.checked)} disabled={disabled} />}
            label=""
        />
    );
}

function UserManagementView() {

    const {
        user,
        userToken,
        setUserToken,
        setUserAccessToken,
        setAdminUserToken
    } = React.useContext(AuthContext)!;

    const navigate = useNavigate();

    const processRowUpdate = React.useCallback(
        async (newRow: GridRowModel, oldRow: GridRowModel) => {
            if (userToken === null || userToken === undefined) {
                return;
            }

            return await new UserDataController(userToken).update(newRow._id, newRow as IUser);
        },
        [userToken],
    );

    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    });
    const [totalRows, setTotalRows] = React.useState<number>(0);
    const [searchText, setSearchText] = useLocalStorage("UserManagementView.searchText", "") as [string, React.Dispatch<React.SetStateAction<string>>];


    const UpdateList = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let response = await new UserDataController(userToken).getPaginated(paginationModel, searchText);
        if (response !== null && response !== undefined) {
            if (response.items !== undefined && response.count !== undefined){
                setList(response.items);
                setTotalRows(response.count)
            }
        }
    }, [userToken, paginationModel, searchText]);

    const columns = React.useMemo<GridColDef[]>(() => {

        const columns: GridColDef[] = [
            { field :'name', headerName: 'Name', width: 250, editable: true },
            { field :'phone_number', headerName: 'Phone Number', width: 150, editable: true },
        ]

        columns.push({ field: 'email_address_copy', headerName: 'Email Address', width: 150, editable: false,
            renderCell: (params) => (
                <>
                    <Stack direction={"row"} sx={{ width: '100%' }}>

                        <Button variant="outlined" color="primary" size={"small"} fullWidth={true} onClick={async () => {
                            let user = params.row as IUser;
                            try {
                                await navigator.clipboard.writeText(user.email_address);
                                console.log('Text copied to clipboard');
                            } catch (err) {
                                console.error('Failed to copy: ', err);
                            }
                        }}>Copy Email</Button>

                        {/*<Typography sx={{ padding: "3px" }}>&nbsp;{params.value}</Typography>*/}
                    </Stack>
                </>
            ),
        });
        columns.push({ field :'email_address', headerName: 'Email Address', width: 400, editable: user?.role === UserRole.ADMIN })
        if (user?.role === UserRole.ADMIN) {

            columns.push({
                field: 'role',
                headerName: 'Role',
                width: 200,
                renderCell: (params) => (
                    <UserRoleChangeCell
                        value={params.value}
                        onChange={async (newValue: string) => {
                            console.log(newValue)
                            let oldRow = params.row as IUser;
                            let newRow = {...oldRow, role: newValue} as IUser;
                            let resultRow = await processRowUpdate(newRow, oldRow);
                            if (resultRow === undefined) {
                                console.log("resultRow is undefined")
                                return;
                            }
                            params.api.updateRows([resultRow]);
                        }}
                    />
                ),
            })
            columns.push({
                field: 'isDeactivated',
                headerName: 'Locked',
                width: 120,
                renderCell: (params) => (
                    <ToggleCell
                        value={params.value as boolean}
                        onToggle={async (newValue: boolean) => {
                            let oldRow = params.row as IUser;
                            let newRow = {...oldRow, isDeactivated: newValue} as IUser;
                            let resultRow = await processRowUpdate(newRow, oldRow);
                            if (resultRow === undefined) {
                                console.log("resultRow is undefined")
                                return;
                            }
                            params.api.updateRows([resultRow]);
                            // Handle toggle here and update the data source (e.g., using setState).
                        }}
                        disabled={(params.row as IUser).role === UserRole.ADMIN}
                    />
                ),
            })

            columns.push({ field: '_id', headerName: 'ID', width: 310, editable: false,
                renderCell: (params) => (
                    <>
                        <Stack direction={"row"} sx={{ width: '100%' }}>

                            <Button variant="outlined" color="primary" size={"small"} fullWidth={false} onClick={async () => {
                                let user = params.row as IUser;
                                try {
                                    await navigator.clipboard.writeText(user._id);
                                    console.log('Text copied to clipboard');
                                } catch (err) {
                                    console.error('Failed to copy: ', err);
                                }
                            }}>Copy</Button>

                            <Typography sx={{ padding: "3px" }}>&nbsp;{params.value}</Typography>
                        </Stack>
                    </>
                ),
            });
        }
        else {
            columns.push({ field: '_id', headerName: 'ID', width: 400, editable: false });
        }

        columns.push({ field: 'resendEmail____1', headerName: ' ', width: 150, editable: false,
            renderCell: (params) => (
                <>
                    {/*<Button variant="outlined" color="primary" disabled={ (params.row as IUser).isDeactivated } fullWidth={true} onClick={async () => {*/}
                    <Button variant="outlined" color="primary" fullWidth={true} onClick={async () => {
                        if (userToken === null || userToken === undefined) {
                            return;
                        }
                        let controller = new ResendNewUserEmailController(userToken);
                        let result = await controller.resendEmail(params.row as IUser);
                        if (result === undefined) {
                            return;
                        }
                        alert("Email Sent");
                    }}>Resend Email</Button>
                </>
            ),
        });


        columns.push({ field: 'reset password', headerName: ' ', width: 180, editable: false,
            renderCell: (params) => (
                <>
                    <Button variant="outlined" color="primary"
                        // disabled={true}
                            disabled={ (params.row as IUser).email_address === "justin.allen@pivital.com" || (params.row as IUser).isDeactivated }
                            fullWidth={true} onClick={async () => {
                        if (window.confirm(`Are you sure you want to reset the password for [${(params.row as IUser).email_address}?`)) {
                            if (userToken === null || userToken === undefined) {
                                return;
                            }
                            let controller = new UserDataController(userToken);
                            let updatedUser = {
                                ...(params.row as IUser),
                                password: `password_${(params.row as IUser).email_address}`
                            };
                            let result = await controller.update((params.row as IUser)._id, updatedUser);
                            if (result === undefined) {
                                return;
                            }
                            await UpdateList();
                        }
                    }}>Reset Password</Button>
                </>
            ),
        });

        if (user?.role === UserRole.ADMIN) {
            columns.push({ field: 'login_as', headerName: ' ', width: 150, editable: false,
                renderCell: (params) => (
                    <>
                        <Button variant="outlined" color="primary" disabled={ (params.row as IUser).role === UserRole.ADMIN || (params.row as IUser).isDeactivated } fullWidth={true} onClick={async () => {
                            if (userToken === null || userToken === undefined) {
                                return;
                            }
                            let controller = new ChangeUserController(userToken);
                            let result = await controller.changeUser(params.row as IUser);
                            if (result === undefined) {
                                return;
                            }
                            setAdminUserToken(userToken);
                            setUserAccessToken(result);
                            setUserToken(result);
                            navigate("/");
                        }}>Login As</Button>
                    </>
                ),
            });

            columns.push({ field: 'lastTokenRefresh', headerName: 'Last Refresh', width: 250, editable: false,
                valueFormatter: (params) => {
                    try {
                        if (params.value === undefined || params.value === null) {
                            return "";
                        }
                        let date = new Date(params.value as string);
                        return date.toLocaleString();
                    }
                    catch (e) {
                        // console.log(e);
                        return "";
                    }

                }
            })



            columns.push({ field: 'user_agents', headerName: ' ', width: 150, editable: false,
                renderCell: (params) => (
                   <UserSessionsModal user={params.row as IUser} />
                ),
            });

            // columns.push({ field: 'projects', headerName: ' ', width: 150, editable: false,
            //     renderCell: (params) => (
            //         <UserProjectsModal user={params.row as IUser} onUpdateRequired={() => {
            //             return new Promise<void>(async (resolve, reject) => {
            //                 if (userToken === null || userToken === undefined) {
            //                     reject();
            //                     return;
            //                 }
            //                 await UpdateList()
            //                 // let all = await new UserDataController(userToken).getAll();
            //                 // setList(all);
            //                 resolve();
            //             });
            //         }} />
            //     ),
            // });

            columns.push({ field: 'revoke_all', headerName: ' ', width: 150, editable: false,
                renderCell: (params) => (
                    <>
                        <Button variant="outlined" color="primary" disabled={ (params.row as IUser).email_address === "justin.allen@pivital.com" || (params.row as IUser).isDeactivated } fullWidth={true} onClick={async () => {
                            if (userToken === null || userToken === undefined) {
                                return;
                            }
                            let controller = new UserDataController(userToken);
                            let updatedUser = {...(params.row as IUser), activeSessions: []};
                            let result = await controller.update((params.row as IUser)._id, updatedUser);
                            if (result === undefined) {
                                return;
                            }
                            await UpdateList();
                        }}>Revoke All Sessions</Button>
                    </>
                ),
            });

            columns.push({ field: 'delete', headerName: ' ', width: 150, editable: false,
                renderCell: (params) => (
                    <>
                        <Button variant="outlined" color="primary" disabled={(params.row as IUser)._id === user._id} fullWidth={true} onClick={async () => {
                            if (userToken === null || userToken === undefined) {
                                return;
                            }
                            if (window.confirm(`Are you sure you want to delete [${(params.row as IUser).email_address}?`)) {

                                let controller = new UserDataController(userToken);
                                await controller.delete((params.row as IUser)._id);
                                await UpdateList();
                            }
                        }}>Delete</Button>
                    </>
                ),
            });
        }


        columns.push({ field: 'createdAt', headerName: 'Created At', width: 250, editable: false,
            valueFormatter: (params) => {
                try {
                    if (params.value === undefined || params.value === null) {
                        return "";
                    }
                    let date = new Date(params.value as string);
                    return date.toLocaleString();
                }
                catch (e) {
                    // console.log(e);
                    return "";
                }

            }
        })

        return columns;
    }, [user, userToken, setUserToken, setAdminUserToken, processRowUpdate, UpdateList, navigate, setUserAccessToken]);



    React.useEffect(() => {
        UpdateList().then();
    }, [userToken, paginationModel.page, paginationModel.pageSize, searchText, UpdateList]);

    const [list, setList] = React.useState<IUser[]>([]);
    const [showAddNewUserModal, setShowAddNewUserModal] = React.useState<boolean>(false);

    const handleProcessRowUpdateError = React.useCallback((error: Error) => {
        console.log('handleProcessRowUpdateError', error)
        alert(error.message);
        // setSnackbar({ children: error.message, severity: 'error' });
    }, []);

    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Users"}
                 onAddClicked={() => {
                     setShowAddNewUserModal(true)
                 }}
                  searchText={{text: searchText, setText: setSearchText, label: "Search (Name, Email, Phone, USER_ID)"}}
            >
                <DataGrid
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: '100%' }}
                    rows={list}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={DEFAULT_PAGE_SIZE_OPTIONS}
                    disableRowSelectionOnClick
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}


                    pagination={true}
                    rowCount={totalRows}
                    paginationMode={"server"}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                />


                <AddNewUser open={showAddNewUserModal} onClose={() => {
                    setShowAddNewUserModal(false)
                }} onAddNewUser={async (user) => {
                    if (userToken === null || userToken === undefined) {
                        return false;
                    }

                    await new UserDataController(userToken).create(user);
                    await UpdateList()
                    // let all = await new UserDataController(userToken).getAll();
                    // setList(all)
                    return true;
                }} />

            </AuthenticatedLayout>
        </div>
    );
}

export default UserManagementView;
