// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import {
    DataGrid,
    GridColDef,
    GridValidRowModel
} from '@mui/x-data-grid';
import AuthenticatedLayout from "../../Library/AuthenticatedLayout";
import {ISession} from "../../types/interfaces";
import {SessionDataController} from "./SessionDataController";
import {AuthContext} from "../../Library/AuthContext";
import {
    Box,
    Button, Modal, Stack, Tooltip, Typography,
} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";
import {UserRole} from "../../types/enums";
// https://mui.com/material-ui/icons/#font-awesome


export class SessionNotificationManagerController  {

    private readonly baseURL : string

    constructor(private userToken: string) {
        // Nothing to do here yet.
        // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;
        const domainURL: string = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
        const protocol: string = `http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }`;
        this.baseURL = `${protocol}://${domainURL}`;
    }


    public async send(session: ISession, item: { title: string, body: string, type: string }): Promise<any> {
        let results = await fetch(`${this.baseURL}/api/notification/${session._id}`, {
            method: "POST",
            headers: {
                "authorization": `Bearer ${this.userToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(item)
        });
        let data = await results.json();
        console.log(data);
        return await data; // this doesn't seem to type check.
    }


}
function SendDeviceNotificationModal(props: { session: ISession })  {

    const {
        // user,
        userToken
    } = React.useContext(AuthContext)!;
    const [show, setShow] = React.useState<boolean>(false);

    return (
        <>
            <Modal
                open={show}
                onClose={() => {
                    setShow(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    height: 500,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}>
                    <Stack style={{height: '100%'}}>


                        <Typography id="modal-modal-title" variant="h5" component="h2" sx={{mb: 2, textAlign: 'center'}}>
                            Notification Sending
                        </Typography>

                        <Typography id="modal-modal-description" sx={{mb: 2, textAlign: 'center'}}>
                            Send a notification to the device.
                        </Typography>

                        <Stack  direction={"row"} spacing={2}>
                            <Button variant="outlined" color="warning" fullWidth={true} onClick={() => {

                                async function sendNotification() {
                                    if (userToken === null || userToken === undefined) {
                                        return;
                                    }
                                    let controller = new SessionNotificationManagerController(userToken);
                                    let results = await controller.send(props.session, {
                                        title: "Test",
                                        body: "Test",
                                        type: "testCategory"
                                    });
                                    console.log(results);
                                    alert("Completed")
                                }

                                sendNotification().then();
                            }}>
                                Send Standard Notification
                            </Button>

                            {/*<Button variant="outlined" color="warning" fullWidth={true} onClick={() => {*/}
                            {/*    async function sendNotification() {*/}
                            {/*        if (userToken === null || userToken === undefined) {*/}
                            {/*            return;*/}
                            {/*        }*/}
                            {/*        let controller = new SessionNotificationManagerController(userToken);*/}
                            {/*        let results = await controller.send(props.session, {*/}
                            {/*            title: "Token Revoked",*/}
                            {/*            body: "Your token has been revoked.",*/}
                            {/*            type: "tokenRevoked"*/}
                            {/*        });*/}
                            {/*        console.log(results);*/}
                            {/*        alert("Completed")*/}
                            {/*    }*/}

                            {/*    sendNotification().then();*/}
                            {/*}}>*/}
                            {/*    Send Token Revocation Notification*/}
                            {/*</Button>*/}
                        </Stack>


                    </Stack>

                </Box>
            </Modal><Tooltip title={"Send Notification"} arrow={true}>
            <Button variant="outlined" color="error" fullWidth={true} onClick={() => {
                setShow(true);
            }}>
                Send Notification
                {/*<FontAwesomeIcon icon={faCopy} />*/}
            </Button>
        </Tooltip>
        </>


    );
}

function SessionManagementView() {

    const {
        user,
        userToken,
        // setUserToken,
        // setAdminUserToken
    } = React.useContext(AuthContext)!;

    const columns = React.useMemo<GridColDef[]>(() => {

        const columns: GridColDef[] = [
            { field: '_id', headerName: 'ID', width: 300, editable: false },
            { field: 'userAgent', headerName: 'User Agent', width: 300, editable: false },
            { field: 'ipAddress', headerName: 'IP Address', width: 300, editable: false },
            { field: 'environment', headerName: 'Environment', width: 300, editable: false },
            { field: 'androidDeviceToken', headerName: 'androidDeviceToken', width: 300, editable: false },
            { field: 'iOSDeviceToken', headerName: 'iOSDeviceToken', width: 300, editable: false },
        ]

        if (user?.role === UserRole.ADMIN) {
            // TODO: add button to trigger direct notification to android and another for iOS
            columns.push({ field: 'sendNotificationAndroid', headerName: ' ', width: 250, editable: false,
                renderCell: (params) => (
                    <>
                        <SendDeviceNotificationModal session={(params.row as ISession)} />
                        {/*<Button variant="outlined" color="primary" fullWidth={true} onClick={async () => {*/}
                        {/*    if (userToken === null || userToken === undefined) {*/}
                        {/*        return;*/}
                        {/*    }*/}
                        {/*    if (window.confirm(`Are you sure you want to send a notification to [${(params.row as ISession).ipAddress}?`)) {*/}

                        {/*        let controller = new SessionDataController(userToken);*/}
                        {/*        // await controller.sendNotification((params.row as ISession)._id);*/}
                        {/*    }*/}
                        {/*}}>Send Notification</Button>*/}
                    </>
                ),
            });

        }

        columns.push({ field: 'delete', headerName: ' ', width: 150, editable: false,
            renderCell: (params) => (
                <>
                    <Button variant="outlined" color="primary" fullWidth={true} onClick={async () => {
                        if (userToken === null || userToken === undefined) {
                            return;
                        }
                        if (window.confirm(`Are you sure you want to revoke [${(params.row as ISession).ipAddress}?`)) {
                            let controller = new SessionDataController(userToken);
                            let sessionNotificationManagerController = new SessionNotificationManagerController(userToken);
                            let results = await sessionNotificationManagerController.send((params.row as ISession), {
                                title: "Token Revoked",
                                body: "Your token has been revoked.",
                                type: "tokenRevoked"
                            });
                            console.log(results);
                            await controller.delete((params.row as ISession)._id);
                            let all = await controller.getAll();
                            setList(all);
                        }
                    }}>Revoke</Button>
                </>
            ),
        });


        // columns.push({ field :'createdAt', headerName: 'Created At', width: 150, editable: true });
        columns.push({ field: 'createdAt', headerName: 'Created At', width: 250, editable: false,
            valueFormatter: (params) => {
                try {
                    if (params.value === undefined || params.value === null) {
                        return "";
                    }
                    let date = new Date(params.value as string);
                    return date.toLocaleString();
                }
                catch (e) {
                    // console.log(e);
                    return "";
                }

            }
        })

        return columns;
    }, [user, userToken]);

    const loadData = React.useCallback(async () => {
        if (userToken === null || userToken === undefined) {
            return;
        }
        let all = await new SessionDataController(userToken).getAll();
        setList(all);
    }, [userToken]); // Dependencies array

    React.useEffect(() => {
        loadData().then();
    }, [userToken, loadData]);

    const [list, setList] = React.useState<ISession[]>([]);



    return (
        <div>
            {/* move the sidebar stuff into a provider system. */}
            <AuthenticatedLayout  pageTitle={"Sessions"}
              customButtons={[
                  {
                      label: "Reload",
                      action: () => {
                          loadData().then();
                      },
                      icon: <FontAwesomeIcon icon={faRotateRight} />
                  }
              ]}
            >
                <DataGrid
                    // make sure to set height to 100% so that the grid fills the whole container
                    style={{ height: '100%' }}
                    rows={list}
                    columns={columns}
                    getRowId={(row: GridValidRowModel) => row._id}
                    pageSizeOptions={[100]}
                    disableRowSelectionOnClick
                />
            </AuthenticatedLayout>
        </div>
    );
}

export default SessionManagementView;
