import {IUser} from "../../types/interfaces";
import React from "react";
import {AuthContext} from "../../Library/AuthContext";
import {UserDataController} from "./UserDataController";
import {Box, Button, Modal, Stack, Tooltip, Typography} from "@mui/material";
import {DataGrid, GridValidRowModel} from "@mui/x-data-grid";

export function UserSessionsModal(props: { user: IUser })  {
    const {
        userToken
    } = React.useContext(AuthContext)!;

    React.useEffect(() => {
        async function loadData() {
            if (userToken === null || userToken === undefined) {
                return;
            }
            let all = await new UserDataController(userToken).getOneWithSessions(props.user._id);
            setUser(all);
        }
        loadData().then();
    }, [props.user, userToken]);

    const [user, setUser] = React.useState<IUser | undefined>();
    const [show, setShow] = React.useState<boolean>(false);

    return (
        <>
            <Modal
                open={show}
                onClose={() => {
                    setShow(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    height: 500,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4
                }}>
                    <Stack style={{height: '100%'}}>


                        <Typography id="modal-modal-title" variant="h5" component="h2" sx={{mb: 2, textAlign: 'center'}}>
                            {props.user.name}'s Sessions
                        </Typography>

                        {user !== undefined && user.activeSessions !== undefined && (
                            <DataGrid
                                // make sure to set height to 100% so that the grid fills the whole container
                                style={{height: '100%'}}
                                rows={user.activeSessions ?? []}
                                columns={[
                                    { field: 'userAgent', headerName: 'User Agent', width: 300, editable: false },
                                    { field: 'ipAddress', headerName: 'IP Address', width: 300, editable: false },
                                    { field: 'androidDeviceToken', headerName: 'androidDeviceToken', width: 300, editable: false },
                                    { field: 'iOSDeviceToken', headerName: 'iOSDeviceToken', width: 300, editable: false },
                                ]}
                                getRowId={(row: GridValidRowModel) => row._id}
                                pageSizeOptions={[100]}
                                disableRowSelectionOnClick/>
                        )}
                    </Stack>

                </Box>
            </Modal><Tooltip title={"Sessions"} arrow={true}>
            <Button variant="outlined" color="error" fullWidth={true} onClick={() => {
                setShow(true);
            }}>
                Sessions
            </Button>
        </Tooltip>
        </>
    );
}
