// https://www.linode.com/docs/guides/authenticating-over-websockets-with-jwt/
// https://developer.okta.com/blog/2020/10/28/secure-web-apps-websockets-nodejs

import React from 'react';
import AuthenticatedLayout from "../Library/AuthenticatedLayout";
import {Typography} from "@mui/material";
// https://mui.com/material-ui/icons/#font-awesome

function UnauthorizedView() {
    return (
        <div>
            <AuthenticatedLayout>
                <Typography variant={"h4"}>Unauthorized</Typography>
                <Typography variant={"body1"}>You don't have permission to access this page.</Typography>
            </AuthenticatedLayout>
        </div>
    );
}

export default UnauthorizedView;