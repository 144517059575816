import {CustomFormField} from "../../types/interfaces";
import React from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from "moment";

export function EditDate<T>(props: { item: T, setItem: any, field: CustomFormField }) {

    let date = props.item[props.field.key as keyof T] as unknown as Date;
    // if (date === undefined || date === null) {
    //     // date = new Date(0);
    //     props.setItem({...props.item, [props.field.key as keyof T]: new Date()});
    // }



    return (
        <>
            {/*<pre>{JSON.stringify(props.item[props.field.key as keyof T])}</pre>*/}
            {/*<pre>{JSON.stringify(date)}</pre>*/}
            <DatePicker
                // fullWidth={true}
                label={props.field.label}

                value={ date === null || date === undefined ? null : moment(date) }

                // value={props.item[props.field.key as keyof T] as unknown as Date}
                // value={  props.item[props.field.key as keyof T] ?? new Date() }
                onChange={(date) => {
                    let newItem = { ...props.item };
                    newItem[props.field.key as keyof T] = date as unknown as T[keyof T];
                    props.setItem(newItem);
                }}
                // defaultValue={new Date()}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        // helperText: 'MM/DD/YYYY',
                    },
                }}
                disabled={props.field.readonly}
            />
        </>
    );
}