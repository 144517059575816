// AuthContext.tsx
import React, { createContext } from 'react';
import {IUser} from "../types/interfaces";

// Create the authentication context
export interface AuthContextType {
    userToken: string | null | undefined;
    setUserToken: React.Dispatch<React.SetStateAction<string | null>>;

    userAccessToken: string | null | undefined;
    setUserAccessToken: React.Dispatch<React.SetStateAction<string | null>>;

    userRefreshToken: string | null | undefined;
    setUserRefreshToken: React.Dispatch<React.SetStateAction<string | null>>;

    user: IUser | null | undefined;
    setUser: React.Dispatch<React.SetStateAction<IUser | null>>;

    userTokenExpiration: Date | null;
    setUserTokenExpiration: React.Dispatch<React.SetStateAction<Date | null>>;

    adminUserToken : string | null | undefined;
    setAdminUserToken: React.Dispatch<React.SetStateAction<string | null>>;

}

export const AuthContext = createContext<AuthContextType | undefined>(undefined);


