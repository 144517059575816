import React from "react";
import {AuthContext} from "../Library/AuthContext";
import {useNavigate} from "react-router-dom";
import {Alert, Box, Button, Grid, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export function LoginModal({ openAccountRecovery } : { openAccountRecovery: () => void }) {

    const {
        setUserToken,
        setUserAccessToken,
        setUserRefreshToken,
        setUserTokenExpiration,
    } = React.useContext(AuthContext)!;
    const navigate = useNavigate();

    const [emailAddress, setEmailAddress] = React.useState<string>("");
    const [password, setPassword] = React.useState<string>("");
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    function onEnterKeyUpLogin(event: any) {
        if (event.key === 'Enter'){
            if (emailAddress === undefined || emailAddress === "") return setErrorMessage("Invalid Email Address");
            if (password === undefined || password === "") return setErrorMessage("Invalid Password");
            Login(emailAddress, password).then(() => {});
        }
    }

    async function Login(email_address: string, password: string) {

        try {
            const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
            // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;

            // TODO: finish this, it's not sending the object yet.
            let results = await fetch(`http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}/api/auth/login`, {
                method: "POST",
                headers: {
                    // "authorization": `Bearer ${this.userToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email_address,
                    password
                })
            });
            let data = await results.json();
            console.log(data);
            let { accessToken, refreshToken, expirationDate } = data;
            if (accessToken === undefined || refreshToken === undefined || expirationDate === undefined) {
                return setErrorMessage("Invalid Credentials")
            }
            setErrorMessage(undefined);

            setUserToken(accessToken);
            setUserAccessToken(accessToken);
            setUserRefreshToken(refreshToken);
            setUserTokenExpiration(expirationDate);

            navigate('/'); // TODO: figure out how to make this work with the useCallback
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Invalid Credentials");
        }
    }


    // babel-preset-react-app is part of the create-react-app project, which
    // remote: #8 41.58 is not maintianed anymore. It is thus unlikely that this bug will
    // remote: #8 41.58 ever be fixed. Add "@babel/plugin-proposal-private-property-in-object" to
    // remote: #8 41.58 your devDependencies to work around this error. This will make this message

    const [showPassword, setShowPassword] = React.useState(false);

    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                LOGIN
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField fullWidth={true} label="Email Address" variant="outlined" type="email" value={emailAddress} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEmailAddress(event.target.value);
                    }}  />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth={true}
                        label="Password"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(event.target.value);
                        }}
                        onKeyUp={onEnterKeyUpLogin}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                </Grid>
                <Grid item xs={12}>
                    <Button type={"submit"} variant={"contained"} size={"large"} fullWidth={true} onClick={() => {
                        if (emailAddress === undefined || emailAddress === "") return setErrorMessage("Invalid Email Address");
                        if (password === undefined || password === "") return setErrorMessage("Invalid Password");
                        setErrorMessage(undefined);
                        Login(emailAddress, password).then(() => {});
                    }}>Login</Button>
                </Grid>

                <Grid item xs={12}>
                    <Button type={"submit"} size={"large"} fullWidth={true} onClick={() => {
                        openAccountRecovery();
                    }}>Reset Password</Button>
                </Grid>
                {errorMessage !== undefined && (
                    <Grid item xs={12}>
                        <Alert severity="error">Invalid Login</Alert>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
}

