import React from "react";
import {CustomFormField} from "../types/interfaces";
import {CustomFormFieldSizes, CustomFormFieldTypes} from "../types/enums";
import {Alert, Box, IconButton, InputAdornment, Typography} from "@mui/material";
import {NextPlan as NextPlanIcon, Send as SendIcon} from "@mui/icons-material";
import {EditObject} from "../Shared/Components/EditObject";

export function AccountRecoveryModal({ closeAccountRecovery } : { closeAccountRecovery: () => void }) {
    enum CodeSendingStatus {
        NotSent,
        Sending,
        Sent
    }

    const [codeSendingStatus, setCodeSendingStatus] = React.useState<CodeSendingStatus>(CodeSendingStatus.NotSent);
    const [item, setItem] = React.useState<any>({});
    const form = React.useMemo(() => {
        let fields: CustomFormField[] = [];

        fields.push({
            kind: CustomFormFieldTypes.EMAIL,
            size: CustomFormFieldSizes.FULL,
            key: "email_address",
            label: `Email Address`,
            endAdornment:
                <InputAdornment position="end">
                    <IconButton
                        disabled={codeSendingStatus === CodeSendingStatus.Sending || codeSendingStatus === CodeSendingStatus.Sent}
                        aria-label="generate new code"
                        onClick={() => {
                            setCodeSendingStatus(CodeSendingStatus.Sending);
                            RequestCode(item.email_address).then(r => {
                                setCodeSendingStatus(CodeSendingStatus.Sent);
                            });
                            // setEmailVerificationSent(VerificationSendingStatus.Sending);
                            // requestVerificationCode().then(r => {});
                        }}
                        edge="end"
                    >
                        <SendIcon />
                    </IconButton>
                </InputAdornment>
        });

        if(item.email_address === null || item.email_address === undefined) {
            return fields;
        }

        if(codeSendingStatus === CodeSendingStatus.NotSent) {
            return fields;
        }

        fields.push({
            kind: CustomFormFieldTypes.EMAIL,
            size: CustomFormFieldSizes.FULL,
            key: "code",
            label: `Code (sent to ${item.email_address})`
        });

        if(item.code === null || item.code === undefined) {
            return fields;
        }

        fields.push({
            kind: CustomFormFieldTypes.PASSWORD,
            size: CustomFormFieldSizes.FULL,
            key: "password",
            label: `Password`
        });

        fields.push({
            kind: CustomFormFieldTypes.PASSWORD,
            size: CustomFormFieldSizes.FULL,
            key: "confirmPassword",
            label: `Confirm Password`,
            endAdornment:
                <InputAdornment position="end">
                    <IconButton
                        disabled={
                            item.password !== item.confirmPassword ||
                            item.password === undefined ||
                            item.password === null ||
                            item.password === "" ||
                            item.confirmPassword === undefined ||
                            item.confirmPassword === null ||
                            item.confirmPassword === ""
                        }
                        aria-label="Submit"
                        onClick={() => {
                            VerifyCode(item.email_address, item.code, item.password).then(r => {
                                closeAccountRecovery();
                            });
                        }}
                        edge="end"
                    >
                        <NextPlanIcon />
                    </IconButton>
                </InputAdornment>
        });

        return fields;
    }, [codeSendingStatus, item, closeAccountRecovery, CodeSendingStatus.NotSent, CodeSendingStatus.Sending, CodeSendingStatus.Sent]);

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    async function RequestCode(email_address: string) {

        try {
            const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";
            // const WS_URL = `ws${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}`;

            // TODO: finish this, it's not sending the object yet.
            let results = await fetch(`http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}/api/recovery/requestCode`, {
                method: "POST",
                headers: {
                    // "authorization": `Bearer ${this.userToken}`,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email_address,
                })
            });
            console.log(results)
            // let data = await results.json();
            setErrorMessage(undefined);
            // setUserToken(token);
            // navigate('/dashboard'); // TODO: figure out how to make this work with the useCallback
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Error Occurred");
        }
    }

    async function VerifyCode(email_address: string, code: string, password: string) {

        try {
            const baseURL = process.env.REACT_APP_SERVER_URL || "127.0.0.1:3000";

            // TODO: finish this, it's not sending the object yet.
            let results = await fetch(`http${ process.env.REACT_APP_SERVER_URL ? "s" :"" }://${baseURL}/api/recovery/verifyCode`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    email_address,
                    code,
                    password
                })
            });
            console.log(results)
            setErrorMessage(undefined);
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Error Occurred");
        }
    }

    return (
        <Box sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4
        }}>
            <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                Password Recovery
            </Typography>

            <EditObject item={item} setItem={setItem} form={form} columns={2}></EditObject>

            {errorMessage !== undefined && (
                <Alert severity="error">{errorMessage}</Alert>
            )}
        </Box>
    );
}
