import {CustomFormField} from "../../types/interfaces";
import {Switch} from "@mui/material";
import React from "react";

export function EditToggle<T>(props: { item: T, setItem: any, field: CustomFormField }) {
    return (
        <>
            <div style={{ padding: '0.5rem' }}>
                <Switch
                    checked={props.item[props.field.key as keyof T] === true }
                    onChange={(e) => {
                        let newItem = { ...props.item };
                        newItem[props.field.key as keyof T] = e.target.checked as unknown as T[keyof T];
                        props.setItem(newItem);
                    }}
                    disabled={props.field.readonly}
                />
                <label>{props.field.label}</label>
            </div>

        </>
    );
}