import {CustomFormField, IUser} from "../../types/interfaces";
import React from "react";
import {AuthContext} from "../../Library/AuthContext";
// import {CompanyDataController} from "../Projects/CompanyDataController";
import {CustomFormFieldSizes, CustomFormFieldTypes, UserRole} from "../../types/enums";
import {Box, Button, Modal, Typography} from "@mui/material";
import {EditObject} from "../../Shared/Components/EditObject";

export const AddNewUser = ({ open, onClose, onAddNewUser } : { open: boolean, onClose: () => void, onAddNewUser: (user: IUser) => Promise<boolean> }) => {
    const [item, setItem] = React.useState<any>({});

    const {
        userToken,
    } = React.useContext(AuthContext)!;

    const fields = React.useMemo(
        () => {
            let fields: CustomFormField[] = [
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "name",
                    label: "Name",
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "email_address",
                    label: "Email Address",
                },
                {
                    kind: CustomFormFieldTypes.TEXT,
                    size: CustomFormFieldSizes.FULL,
                    key: "phone_number",
                    label: "Phone Number",
                },
                {
                    kind: CustomFormFieldTypes.SELECT,
                    size: CustomFormFieldSizes.FULL,
                    key: "role",
                    label: "Role",
                    options: [
                        { label: "Admin", value: UserRole.ADMIN },
                        // { label: "User Manager", value: UserRole.USER_MANAGER},
                        // { label: "Manager / PC", value: UserRole.MANAGER },
                        // { label: "Technician", value: UserRole.TECHNICIAN },
                        // { label: "Client", value: UserRole.CLIENT }
                    ]
                },

            ];


            return fields;
        },
        [item]
    );

    const disableButton = React.useMemo(() => {
        return item.name === undefined || item.name === "" ||
            item.email_address === undefined || item.email_address === "" ||
            item.phone_number === undefined || item.phone_number === "" ||
            item.role === undefined || item.role === "";
    }, [item]);

    return (<>
        <Modal open={open} onClose={() => {
            setItem({});
            onClose();
        }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '60%',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ mb: 2, textAlign: 'center' }}>
                    Create New User
                </Typography>
                <EditObject
                    item={item}
                    setItem={setItem}
                    form={fields}
                    columns={12}
                />
                <br />

                <Button type={"submit"} variant={"contained"} size={"large"} disabled={disableButton} fullWidth={true} onClick={async () => {
                    let userToCreate: IUser = {
                        _id: "", // this will be ignored by the server.
                        name: item.name,
                        email_address: item.email_address,
                        phone_number: item.phone_number,
                        role: item.role,
                        activeSessions: [],

                    };

                    try {
                        if (await onAddNewUser(userToCreate)) {
                            setItem({});
                            onClose();
                        } else {
                            // alert("Invalid User");
                            alert("Could not create user");
                        }
                    }
                    catch (e) {
                        const error = e as Error;
                        console.log(error);
                        // alert("Error Creating User");
                        switch (error.message) {
                            case "Conflict":
                                alert("User with this email address already exists.");
                                return;
                        }
                        alert(`Could not create user, error occurred. ${error.message}`);
                    }


                }}>CREATE</Button>
            </Box>
        </Modal>
    </>);
};
