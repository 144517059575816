// AuthContext.tsx
import React, { createContext } from 'react';

// Create the authentication context
export interface SidebarContextType {
    showSidebar: boolean;
    setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SidebarContext = createContext<SidebarContextType>({showSidebar: false, setShowSidebar: () => {
    console.error("setShowSidebar not implemented");
}});


